import React, { useState, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import ReactECharts from 'echarts-for-react';
import { 
  getBtcPriceReturn, 
  getBtcOverviewBase, 
  getBtcHistory,
  formatNumber
} from '../services/data_api';
import './Overview.css';
import MonthlyReturnsHeatmap from './MonthlyReturnsHeatmap';

// 添加日期格式化函数
const formatDate = (dateString) => {
  if (!dateString) return '';
  return new Date(dateString).toLocaleDateString('en-US', {
    year: 'numeric',
    month: 'short',
    day: 'numeric'
  });
};

// 添加时间区间选择的常量
const TIME_RANGES = [
  { label: '1M', days: 30 },
  { label: '3M', days: 90 },
  { label: '6M', days: 180 },
  { label: '1Y', days: 365 },
  { label: 'ALL', days: 0 }
];

const Overview = () => {
  const { t } = useTranslation();
  const [priceData, setPriceData] = useState(null);
  const [overviewData, setOverviewData] = useState(null);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const [historyData, setHistoryData] = useState(null);
  const [selectedRange, setSelectedRange] = useState('ALL');
  const [showFullIntro, setShowFullIntro] = useState(false);

  useEffect(() => {
    const fetchData = async () => {
      try {
        const [priceResponse, overviewResponse, historyResponse] = await Promise.all([
          getBtcPriceReturn(),
          getBtcOverviewBase(),
          getBtcHistory()
        ]);
        
        // 正确获取数据数组的第一个元素
        const priceData = priceResponse?.data?.[0];
        const overviewData = overviewResponse?.data?.[0];
        
        console.log('Price Data:', priceData);
        console.log('Overview Data:', overviewData);
        
        setPriceData(priceData);
        setOverviewData(overviewData);
        setHistoryData(historyResponse?.data);
        setLoading(false);
      } catch (err) {
        console.error('Fetch Error:', err);
        setError(err.message);
        setLoading(false);
      }
    };
    fetchData();
  }, []);

  const getHistoryChartOption = () => {
    if (!historyData || !Array.isArray(historyData) || historyData.length === 0) {
      return {
        title: {
          text: 'No data available',
          textStyle: {
            color: '#666',
            fontSize: 14
          },
          left: 'center',
          top: 'center'
        }
      };
    }

    // 根据选择的时间范围过滤数据
    const filterDataByRange = (data, range) => {
      if (range === 'ALL') return data;
      const cutoffDate = new Date();
      cutoffDate.setDate(cutoffDate.getDate() - TIME_RANGES.find(r => r.label === range).days);
      return data.filter(item => new Date(item.date) >= cutoffDate);
    };

    const validData = filterDataByRange(
      historyData.filter(item => 
        item && 
        item.date && 
        typeof item.close_price === 'number' && 
        typeof item.volume === 'number'
      ),
      selectedRange
    );

    return {
      backgroundColor: '#ffffff',
      textStyle: {
        fontFamily: '-apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, "Helvetica Neue", Arial',
        fontSize: 12
      },
      tooltip: {
        trigger: 'axis',
        axisPointer: {
          type: 'cross',
          label: {
            backgroundColor: '#1a73e8'
          }
        },
        backgroundColor: 'rgba(255, 255, 255, 0.95)',
        borderColor: '#eee',
        borderWidth: 1,
        textStyle: {
          color: '#333'
        },
        formatter: function(params) {
          const date = params[0].axisValue;
          const price = params[0].data.toLocaleString('en-US', {
            style: 'currency',
            currency: 'USD'
          });
          const volume = params[1].data.toLocaleString('en-US');
          return `<div style="padding: 3px;">
            <div style="margin-bottom: 4px;"><strong>${date}</strong></div>
            <div style="color: #1a73e8;">Price: ${price}</div>
            <div style="color: #34a853;">Volume: ${volume}</div>
          </div>`;
        }
      },
      dataZoom: [
        {
          type: 'inside',
          start: 0,
          end: 100,
          zoomLock: false
        },
        {
          type: 'slider',
          show: true,
          height: 30,
          bottom: 5,
          borderColor: '#ddd',
          fillerColor: 'rgba(26, 115, 232, 0.1)',
          handleStyle: {
            color: '#1a73e8'
          }
        }
      ],
      grid: {
        left: '3%',
        right: '4%',
        bottom: '15%',
        top: '15%',
        containLabel: true
      },
      xAxis: [{
        type: 'category',
        data: validData.map(item => item.date),
        axisLine: {
          lineStyle: {
            color: '#ddd'
          }
        },
        axisLabel: {
          rotate: 45,
          color: '#666',
          fontSize: 11
        }
      }],
      yAxis: [
        {
          type: 'value',
          name: 'Price (USD)',
          position: 'right',
          nameTextStyle: {
            color: '#1a73e8',
            fontSize: 20,
            padding: [0, 0, 10, 0],
            align: 'center'
          },
          axisLine: {
            show: true,
            lineStyle: {
              color: '#1a73e8'
            }
          },
          axisLabel: {
            formatter: '${value}',
            color: '#666'
          },
          splitLine: {
            lineStyle: {
              color: '#f5f5f5'
            }
          }
        },
        {
          type: 'value',
          name: 'Volume',
          position: 'left',
          nameTextStyle: {
            color: '#34a853',
            fontSize: 20,
            padding: [0, 0, 10, 0],
            align: 'center'
          },
          axisLine: {
            show: true,
            lineStyle: {
              color: '#34a853'
            }
          },
          axisLabel: {
            color: '#666'
          },
          splitLine: {
            show: false
          }
        }
      ],
      series: [
        {
          name: 'Price',
          type: 'line',
          data: validData.map(item => item.close_price),
          smooth: true,
          symbol: 'none',
          lineStyle: {
            width: 2,
            color: '#1a73e8'
          },
          areaStyle: {
            color: {
              type: 'linear',
              x: 0,
              y: 0,
              x2: 0,
              y2: 1,
              colorStops: [{
                offset: 0,
                color: 'rgba(26, 115, 232, 0.2)'
              }, {
                offset: 1,
                color: 'rgba(26, 115, 232, 0)'
              }]
            }
          }
        },
        {
          name: 'Volume',
          type: 'bar',
          yAxisIndex: 1,
          data: validData.map(item => item.volume),
          itemStyle: {
            color: '#34a853',
            opacity: 0.3
          }
        }
      ]
    };
  };

  // 添加数据检查辅助函数
  const isDataReady = () => {
    return !loading && !error && priceData && overviewData;
  };

  // 渲染加载状态
  if (loading) {
    return <div className="loading-state">Loading...</div>;
  }

  // 渲染错误态
  if (error) {
    return <div className="error-state">Error: {error}</div>;
  }

  // 检查数据是否准备就绪
  if (!isDataReady()) {
    return <div className="no-data-state">No data available</div>;
  }

  // 安全地访问数据的辅助函数
  const safeNumber = (value) => value || 0;

  return (
    <div className="overview-component">
      <div className="overview-btc-header">
        <div className="overview-btc-title">
          <img src="/images/Bitcoinlogo.png" alt="BTC" className="overview-btc-logo" />
          <h1>Bitcoin. (BTC)</h1>
        </div>
      </div>

      <div className="metrics-grid">
        {/* Price Section */}
        <div className="metric-section">
          <h3>Price</h3>
          <div className="price-container">
            <div className="price-value">
              ${safeNumber(priceData?.current_price).toLocaleString()}
            </div>
            <div className={`price-change ${Number(priceData?.return_to_previous || 0) >= 0 ? 'positive' : 'negative'}`}>
              ({Number(priceData?.return_to_previous || 0).toFixed(2)}%)
            </div>
          </div>
        </div>

        {/* Market Cap Section */}
        <div className="metric-section">
          <h3>Market Cap</h3>
          <div className="data-grid">
            <div className="data-item">
              <span className="data-value">
                ${formatNumber(safeNumber(priceData?.current_price || 0) * safeNumber(overviewData?.circulating_supply || 0))}
              </span>
            </div>
          </div>
        </div>

        {/* Volume Section */}
        <div className="metric-section">
          <h3>Volume</h3>
          <div className="data-grid">
            <div className="data-item">
              <span className="data-label">24h</span>
              <span className="data-value">${formatNumber(safeNumber(overviewData?.volume_24h))}</span>
            </div>
            <div className="data-item">
              <span className="data-label">10D</span>
              <span className="data-value">${formatNumber(safeNumber(overviewData?.volume_10d_avg))}</span>
            </div>
          </div>
        </div>

        {/* Supply Section */}
        <div className="metric-section">
          <h3>Supply</h3>
          <div className="data-grid">
            <div className="data-item">
              <span className="data-label">Circulating</span>
              <span className="data-value">{formatNumber(safeNumber(overviewData?.circulating_supply))}</span>
            </div>
            <div className="data-item">
              <span className="data-label">Total</span>
              <span className="data-value">{formatNumber(safeNumber(overviewData?.total_supply))}</span>
            </div>
          </div>
        </div>
      </div>

      <div className="historical-data">
        <div className="historical-item">
          <div className="historical-label">All-time High</div>
          <div className="historical-value">
            ${safeNumber(priceData?.all_time_high).toLocaleString()}
          </div>
        </div>
        <div className="historical-item">
          <div className="historical-label">%from ATH</div>
          <div className={`historical-value ${Number(priceData?.return_to_ath || 0) >= 0 ? 'positive' : 'negative'}`}>
            {Number(priceData?.return_to_ath || 0).toFixed(2)}%
          </div>
        </div>
        <div className="historical-item">
          <div className="historical-label">ATH Date</div>
          <div className="historical-value">
            {overviewData?.ath_date ? formatDate(overviewData.ath_date) : '-'}
          </div>
        </div>
        <div className="historical-item">
          <div className="historical-label">Launch Date</div>
          <div className="historical-value">
            {overviewData?.launch_date ? formatDate(overviewData.launch_date) : '-'}
          </div>
        </div>
      </div>

      {/* Introduction Section */}
      <div className="introduction-section">
        <h3>{t('introduction')}</h3>
        <div className="introduction-content">
          <p>
            {showFullIntro 
              ? t('bitcoinIntroduction')
              : `${t('bitcoinIntroduction').slice(0, 200)}...`}
          </p>
          <button 
            className="show-more-button"
            onClick={() => setShowFullIntro(!showFullIntro)}
          >
            {t(showFullIntro ? 'showLess' : 'showMore')}
          </button>
        </div>
      </div>

      {/* Historical Price Chart */}
      <div className="history-chart-section">
        <div className="chart-header">
          <h3>{t('Historical Price & Volume')}</h3>
          <div className="time-range-selector">
            {TIME_RANGES.map(range => (
              <button
                key={range.label}
                className={`range-button ${selectedRange === range.label ? 'active' : ''}`}
                onClick={() => setSelectedRange(range.label)}
              >
                {range.label}
              </button>
            ))}
          </div>
        </div>
        <ReactECharts 
          option={getHistoryChartOption()} 
          style={{ height: '400px' }}
          notMerge={true}
        />
      </div>
      <MonthlyReturnsHeatmap />
    </div>
  );
};

export default Overview; 