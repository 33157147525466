// For local development, use the following line:
const apiBaseUrl = 'https://user-mgmt.helloswx.workers.dev';

// For production, use the following line with your domain name:
//const apiBaseUrl = 'https://user-mgmt.yourdomain.com';

// Or, if you're running the user-mgmt worker under a route on the same domain as this front end:
// const apiBaseUrl = `${window.location.protocol}//${window.location.host}/user-api`;

export function callLoginApi(endpoint, data = null) {
    const url = apiBaseUrl + endpoint;
    const options = {
        credentials: 'include',
        crossDomain: true,
        method: 'GET',
        headers: {
            'Content-Type': 'application/json'
        },
    };
    
    if (data) {
        options.body = JSON.stringify(data);
        options.method = 'POST';
    }

    return fetch(url, options)
        .then(async response => {
            if (!response.ok) {
                const errorData = await response.json();
                throw new Error(errorData.error || 'Network response was not ok');
            }
            return response.json();
        });
}
