import React, { useEffect, useState } from 'react';
import { Routes, Route, Navigate } from 'react-router-dom';
import { useSelector, useDispatch } from 'react-redux';
import MainPage from './components/MainPage';
import AboutPage from './components/AboutPage';
import FAQPage from './components/FAQPage';
import PricingPage from './components/PricingPage';
import LoginPage from './components/LoginPage';
import RegisterPage from './components/RegisterPage';
import UserProfilePage from './components/UserProfilePage';
import AppHeader from './components/AppHeader';
import { callLoginApi } from './login_api';
import './App.css';
import PaymentSuccessPage from './components/PaymentSuccessPage';
import PaymentFailurePage from './components/PaymentFailurePage';
import { getSubscriptionInfo } from './services/stripe_api';
import ProductsPage from './components/ProductsPage';

// 添加错误边界组件
class ErrorBoundary extends React.Component {
  state = { hasError: false };

  static getDerivedStateFromError(error) {
    return { hasError: true };
  }

  componentDidCatch(error, errorInfo) {
    console.error('App Error:', error, errorInfo);
  }

  render() {
    if (this.state.hasError) {
      return (
        <div style={{ textAlign: 'center', padding: '20px' }}>
          <h1>Something went wrong</h1>
          <button onClick={() => window.location.reload()}>
            Refresh Page
          </button>
        </div>
      );
    }
    return this.props.children;
  }
}

function App() {
  const [error, setError] = useState(null);
  const isLoggedIn = useSelector(state => state.auth?.isLoggedIn || false);
  const userData = useSelector(state => state.auth?.userData || null);
  const dispatch = useDispatch();

  useEffect(() => {
    console.log('App component mounted');
    const checkSession = async () => {
      if (localStorage.getItem('isLoggedIn') === 'true') {
        try {
          console.log('Checking session...');
          const userData = await callLoginApi('/load-user');
          console.log('User data received:', userData);
          
          dispatch({ type: 'SET_USER_DATA', payload: userData });
          
          if (userData?.userid) {
            try {
              const subscriptionData = await getSubscriptionInfo(userData.userid);
              dispatch({ 
                type: 'UPDATE_SUBSCRIPTION_DATA', 
                payload: subscriptionData 
              });
            } catch (error) {
              console.error('Subscription fetch failed:', error);
            }
          }
        } catch (error) {
          console.error('Session check failed:', error);
          dispatch({ type: 'SET_LOGGED_OUT' });
        }
      }
    };

    checkSession().catch(err => {
      console.error('Session check error:', err);
      setError(err.message);
    });
  }, [dispatch]);

  if (error) {
    return (
      <div style={{ textAlign: 'center', padding: '20px' }}>
        <h1>Error Loading Application</h1>
        <p>{error}</p>
        <button onClick={() => window.location.reload()}>
          Refresh Page
        </button>
      </div>
    );
  }

  return (
    <ErrorBoundary>
      <div className="app-container">
        <AppHeader />
        <main>
          <Routes>
            <Route path="/" element={<MainPage />} />
            <Route path="/about" element={<AboutPage />} />
            <Route path="/faq" element={<FAQPage />} />
            <Route path="/pricing" element={<PricingPage />} />
            <Route 
              path="/login" 
              element={
                isLoggedIn ? (
                  <Navigate to="/profile" replace />
                ) : (
                  <LoginPage />
                )
              } 
            />
            <Route 
              path="/register" 
              element={
                isLoggedIn ? (
                  <Navigate to="/profile" replace />
                ) : (
                  <RegisterPage />
                )
              } 
            />
            <Route 
              path="/profile" 
              element={
                isLoggedIn ? (
                  <UserProfilePage />
                ) : (
                  <Navigate to="/login" replace />
                )
              } 
            />
            <Route path="/payment/success" element={<PaymentSuccessPage />} />
            <Route path="/payment/failure" element={<PaymentFailurePage />} />
            <Route path="/products" element={<ProductsPage />} />
          </Routes>
        </main>
      </div>
    </ErrorBoundary>
  );
}

export default App;
