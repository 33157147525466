import i18n from 'i18next';
import { initReactI18next } from 'react-i18next';
import Backend from 'i18next-http-backend';
import LanguageDetector from 'i18next-browser-languagedetector';

i18n
  .use(Backend)
  .use(LanguageDetector)
  .use(initReactI18next)
  .init({
    fallbackLng: 'en',
    debug: true,
    interpolation: {
      escapeValue: false,
    },
    returnObjects: true,
    backend: {
      loadPath: '/locales/{{lng}}/translation.json',
      parse: (data) => {
        try {
          return JSON.parse(data);
        } catch (e) {
          console.error('Error parsing translation file:', e);
          return {};
        }
      }
    },
    detection: {
      order: ['querystring', 'cookie', 'localStorage', 'navigator'],
      caches: ['localStorage', 'cookie'],
    }
  });

export default i18n;
