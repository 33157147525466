import React from 'react';
import { useTranslation } from 'react-i18next';
import './LongTermOverview.css';

const LongTermOverview = ({ loading, error }) => {
  const { t } = useTranslation();

  if (loading) return <div>Loading...</div>;
  if (error) return <div>Error: {error}</div>;

  return (
    <div className="long-term-overview">
      <div className="overview-header">
        <h1>Long-term Investment Overview</h1>
        {/* 这里可以根据需要添加更多内容 */}
      </div>
    </div>
  );
};

export default LongTermOverview; 