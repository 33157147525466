import { loadStripe } from '@stripe/stripe-js';
import { callLoginApi } from '../login_api';
import store from '../store/index';

const apiBaseUrl = 'https://my-stripe-worker.helloswx.workers.dev';

const STRIPE_PUBLIC_KEY = 'pk_test_51ORGxLBR55EZVoQVKiY1FzoeCvSj4MUHT7vRF6ilMnWoFuqExkPGgLJRsHbWpZInkQOQe4wZHHHi0qySl0v4vTfu001sf5N5z3';
export const stripePromise = loadStripe(STRIPE_PUBLIC_KEY);

const fetchWithTimeout = async (url, options, timeout = 5000, retries = 3) => {
    const controller = new AbortController();
    const timeoutId = setTimeout(() => controller.abort(), timeout);
    
    const defaultOptions = {
        headers: {
            'Content-Type': 'application/json',
            'Accept': 'application/json'
        }
    };

    for (let i = 0; i < retries; i++) {
        try {
            const response = await fetch(url, {
                ...defaultOptions,
                ...options,
                signal: controller.signal
            });
            clearTimeout(timeoutId);
            return response;
        } catch (error) {
            if (i === retries - 1) throw error;
            console.log(`Retry attempt ${i + 1} of ${retries}`);
            await new Promise(resolve => setTimeout(resolve, 1000 * (i + 1)));
        }
    }
};

// 统一的 session 检查和超时重试逻辑
const makeApiRequest = async (url, options = {}, requiresAuth = true) => {
  if (requiresAuth) {
    const sessionValid = await checkAndRefreshSession();
    if (!sessionValid) {
      throw new Error('Session expired');
    }
  }

  return fetchWithTimeout(url, {
    ...options,
    headers: {
      'Content-Type': 'application/json',
      'Accept': 'application/json',
      'Origin': window.location.origin,
      ...options.headers
    },
    credentials: 'include',
    mode: 'cors'
  }, 5000, 3);
};

export async function getSubscriptionInfo(userId) {
  if (!userId) {
    throw new Error('User ID is required');
  }

  const url = `${apiBaseUrl}/subscription/${userId}`;
  try {
    const response = await makeApiRequest(url, { method: 'GET' });
    
    if (!response.ok) {
      const errorData = await response.json().catch(() => ({}));
      throw new Error(errorData.error || `Failed to fetch subscription info: ${response.status}`);
    }

    return await response.json();
  } catch (err) {
    if (err.name === 'AbortError') {
      throw new Error('Request timeout - please try again');
    }
    throw err;
  }
}

const checkAndRefreshSession = async () => {
  if (localStorage.getItem('isLoggedIn') === 'true') {
    try {
      const userData = await callLoginApi('/load-user');
      store.dispatch({ type: 'SET_USER_DATA', payload: userData });
      return true;
    } catch (error) {
      console.error('Session refresh failed:', error);
      return false;
    }
  }
  return false;
};

export async function createCheckoutSession(priceId, userId, subscriptionType) {
  const url = `${apiBaseUrl}/create-checkout-session`;
  try {
    const response = await makeApiRequest(
      url, 
      {
        method: 'POST',
        credentials: 'include',
        body: JSON.stringify({ 
          priceId, 
          userId, 
          subscriptionType,
          successUrl: `${window.location.origin}/payment-success`,
          cancelUrl: `${window.location.origin}/pricing`
        })
      }
    );
    
    if (!response.ok) {
      const errorData = await response.json();
      throw new Error(errorData.error || 'Failed to create checkout session');
    }
    
    return await response.json();
  } catch (err) {
    console.error('Checkout session error:', err);
    throw new Error(err.message || 'Failed to create checkout session');
  }
}

export async function activateOneDayPass(userId) {
    const url = `${apiBaseUrl}/activate-one-day-pass`;
    const response = await fetch(url, {
        method: 'POST',
        headers: {
            'Content-Type': 'application/json',
            'Accept': 'application/json'
        },
        body: JSON.stringify({ userId })
    });

    if (!response.ok) {
        const errorData = await response.json();
        throw new Error(errorData.error || 'Failed to activate one-day pass');
    }
    return response.json();
}