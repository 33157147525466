import React, { useState, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { getStockOverview } from '../../services/data_api';
import Tooltip from '../Tooltip';
import './StockScreener.css';

const FilterTags = ({ filters, onRemove }) => {
  return (
    <div className="screener-filter-tags">
      {Object.entries(filters).map(([column, value]) => {
        if (!value) return null;  // 如果没有值，不显示标签
        return (
          <span key={`${column}-${value}`} className="screener-tag">
            {`${column}: ${value}`}
            <button 
              className="screener-remove-tag" 
              onClick={() => onRemove(column, value)}
            >
              ×
            </button>
          </span>
        );
      })}
    </div>
  );
};

const FilterSection = ({ onFilter }) => {
  const { t } = useTranslation();
  const [filters, setFilters] = useState({});
  
  const filterTypes = [
    { 
      key: 'bci', 
      label: 'BTC Correlation Index (BCI)',
      tooltip: t('tooltips.bci'),
      options: [
        { value: 'A', label: 'Very Strong' },
        { value: 'B', label: 'Strong' },
        { value: 'C', label: 'Medium' },
        { value: 'D', label: 'Weak' },
        { value: 'E', label: 'Very Weak' }
      ]
    },
    { 
      key: 'vp', 
      label: 'Valuation Premium (VP)',
      tooltip: t('tooltips.vp'),
      options: [
        { value: 'A', label: 'Very High' },
        { value: 'B', label: 'High' },
        { value: 'C', label: 'Medium' },
        { value: 'D', label: 'Low' },
        { value: 'E', label: 'Very Low' }
      ]
    },
    { 
      key: 'risk', 
      label: 'Risk',
      tooltip: t('tooltips.risk'),
      options: [
        { value: 'A', label: 'Very High' },
        { value: 'B', label: 'High' },
        { value: 'C', label: 'Medium' },
        { value: 'D', label: 'Low' },
        { value: 'E', label: 'Very Low' }
      ]
    },
    { 
      key: 'sentiment', 
      label: 'Sentiment Rank',
      tooltip: t('tooltips.sentiment'),
      options: [
        { value: 'A', label: 'Very Bullish' },
        { value: 'B', label: 'Bullish' },
        { value: 'C', label: 'Neutral' },
        { value: 'D', label: 'Bearish' },
        { value: 'E', label: 'Very Bearish' }
      ]
    }
  ];

  const handleFilterChange = (key, value) => {
    const newFilters = { ...filters };
    if (value) {
      newFilters[key] = value;
    } else {
      delete newFilters[key];
    }
    setFilters(newFilters);
    onFilter(newFilters);
  };

  return (
    <div className="stock-screener-filter-section">
      <div className="stock-screener-filter-controls">
        {filterTypes.map(filter => (
          <div key={filter.key} className="stock-screener-filter-group">
            <div className="stock-screener-filter-header">
              <Tooltip content={filter.tooltip}>
                <span>{filter.label}</span>
              </Tooltip>
            </div>
            <select
              className="stock-screener-filter-input"
              value={filters[filter.key] || ''}
              onChange={(e) => handleFilterChange(filter.key, e.target.value)}
            >
              <option value="">All</option>
              {filter.options.map(option => (
                <option key={option.value} value={option.value}>
                  {option.label}
                </option>
              ))}
            </select>
          </div>
        ))}
      </div>
      {Object.keys(filters).length > 0 && (
        <div className="stock-screener-active-filters">
          {Object.entries(filters).map(([key, value]) => {
            const filterType = filterTypes.find(f => f.key === key);
            const option = filterType?.options.find(o => o.value === value);
            if (filterType && option) {
              return (
                <span key={key} className="stock-screener-filter-tag">
                  {`${filterType.label}: ${option.label}`}
                  <button
                    className="stock-screener-remove-tag"
                    onClick={() => handleFilterChange(key, '')}
                  >
                    ×
                  </button>
                </span>
              );
            }
            return null;
          })}
        </div>
      )}
    </div>
  );
};

const StockScreener = () => {
  const { t } = useTranslation();
  const [stockData, setStockData] = useState(null);
  const [stockLoading, setStockLoading] = useState(true);
  const [stockError, setStockError] = useState(null);
  const [sortConfig, setSortConfig] = useState({
    key: null,
    direction: 'ascending'
  });
  const [filters, setFilters] = useState({
    bci: '',
    vp: '',
    risk: '',
    sentiment_rank: ''
  });

  const columns = [
    { key: 'ticker', label: 'Ticker' },
    { key: 'name', label: 'Name' },
    { key: 'close', label: 'Close' },
    { 
      key: 'recent_performance', 
      label: 'Recent Performance',
      tooltip: 'tooltips.recentPerformance'
    },
    { 
      key: 'bci', 
      label: 'BTC Correlation Index (BCI)', 
      tooltip: 'tooltips.bci'
    },
    { 
      key: 'vp', 
      label: 'Valuation Premium (VP)', 
      tooltip: 'tooltips.vp'
    },
    { 
      key: 'risk', 
      label: 'Risk', 
      tooltip: 'tooltips.risk'
    },
    { 
      key: 'sentiment_rank', 
      label: 'Sentiment rank', 
      tooltip: 'tooltips.sentiment'
    }
  ];

  useEffect(() => {
    const fetchStockData = async () => {
      try {
        const data = await getStockOverview();
        setStockData(data);
        setStockLoading(false);
      } catch (err) {
        setStockError(err.message);
        setStockLoading(false);
      }
    };

    fetchStockData();
  }, []);

  const sortData = (data, key) => {
    return [...data].sort((a, b) => {
      if (key === 'close' || key === 'return') {
        return parseFloat(a[key]) - parseFloat(b[key]);
      }
      if (['bci', 'vp', 'risk', 'sentiment'].includes(key)) {
        return a[key].localeCompare(b[key]);
      }
      return a[key].toString().localeCompare(b[key].toString());
    });
  };

  const handleSort = (key) => {
    let direction = 'ascending';
    if (sortConfig.key === key && sortConfig.direction === 'ascending') {
      direction = 'descending';
    }
    setSortConfig({ key, direction });
  };

  const getSortedData = () => {
    if (!sortConfig.key || !stockData?.data) return stockData?.data || [];
    const sortedData = sortData(stockData.data, sortConfig.key);
    return sortConfig.direction === 'descending' ? sortedData.reverse() : sortedData;
  };

  const getFilteredData = () => {
    let data = getSortedData();
    
    // 创建等级映射关系
    const gradeMap = {
      'Very Strong': 'A',
      'Strong': 'B',
      'Medium': 'C',
      'Weak': 'D',
      'Very Weak': 'E',
      'Very High': 'A',
      'High': 'B',
      'Low': 'D',
      'Very Low': 'E',
      'Very Bullish': 'A',
      'Bullish': 'B',
      'Neutral': 'C',
      'Bearish': 'D',
      'Very Bearish': 'E'
    };
    
    // 处理筛选
    Object.entries(filters).forEach(([column, value]) => {
      if (value) {
        const grade = gradeMap[value];
        if (grade) {
          data = data.filter(item => {
            // 处理 sentiment 的特殊情况
            const fieldName = column === 'sentiment' ? 'sentiment_rank' : column;
            return item[fieldName] === grade;
          });
        }
      }
    });
    
    return data;
  };

  const handleFilter = (column, value) => {
    setFilters(prev => {
      const newFilters = { ...prev };
      if (value) {
        // 如果已经选中该值，则清除
        if (newFilters[column] === value) {
          newFilters[column] = '';
        } else {
          // 否则设置新值
          newFilters[column] = value;
        }
      } else {
        newFilters[column] = '';
      }
      return newFilters;
    });
  };

  const renderGrade = (grade, type) => {
    if (!grade) return '-';
    
    const getGradeColor = (grade, type) => {
      if (type === 'risk') {
        const riskColors = {
          'A': '#D32F2F', // very high risk
          'B': '#F57C00', // high risk
          'C': '#FDD835', // medium risk
          'D': '#388E3C', // low risk
          'E': '#1B5E20'  // very low risk
        };
        return riskColors[grade] || '#ddd';
      }
      
      const colors = {
        'A': '#388E3C',
        'B': '#388E3C',
        'C': '#FDD835',
        'D': '#F57C00',
        'E': '#D32F2F'
      };
      return colors[grade] || '#ddd';
    };

    const getDescription = (type, grade) => {
      const descriptions = {
        bci: {
          'A': 'Very Strong',
          'B': 'Strong',
          'C': 'Medium',
          'D': 'Weak',
          'E': 'Very Weak'
        },
        vp: {
          'A': 'Very High',
          'B': 'High',
          'C': 'Medium',
          'D': 'Low',
          'E': 'Very Low'
        },
        risk: {
          'A': 'Very High',
          'B': 'High',
          'C': 'Medium',
          'D': 'Low',
          'E': 'Very Low'
        },
        sentiment: {
          'A': 'Very Bullish',
          'B': 'Bullish',
          'C': 'Neutral',
          'D': 'Bearish',
          'E': 'Very Bearish'
        }
      };
      return descriptions[type]?.[grade] || grade;
    };

    return (
      <div className="stock-screener-grade-container">
        {['bci', 'sentiment'].includes(type) && (
          <>
            <div className="stock-screener-horizontal-indicator">
              <div className="stock-screener-marker" style={{
                left: grade === 'A' ? '100%' : 
                      grade === 'B' ? '75%' : 
                      grade === 'C' ? '50%' : 
                      grade === 'D' ? '25%' : '0%'
              }}></div>
            </div>
            <span className="stock-screener-grade-description">
              {getDescription(type, grade)}
            </span>
          </>
        )}
        {['vp', 'risk'].includes(type) && (
          <>
            <div className="stock-screener-stacked-bars">
              {[...Array(5)].map((_, i) => (
                <div
                  key={i}
                  className="stock-screener-bar"
                  style={{
                    backgroundColor: i < (
                      grade === 'A' ? 5 :
                      grade === 'B' ? 4 :
                      grade === 'C' ? 3 :
                      grade === 'D' ? 2 : 1
                    ) ? getGradeColor(grade, type) : '#ddd'
                  }}
                />
              ))}
            </div>
            <span className="stock-screener-grade-description">
              {getDescription(type, grade)}
            </span>
          </>
        )}
      </div>
    );
  };

  const getColumnAlignment = (key) => {
    if (['ticker', 'name'].includes(key)) {
      return 'stock-screener-align-left';
    }
    if (['close', 'recent_performance'].includes(key)) {
      return 'stock-screener-align-right';
    }
    return 'stock-screener-align-center';
  };

  if (stockLoading) return <div>Loading...</div>;
  if (stockError) return <div>Error: {stockError}</div>;
  if (!stockData?.data) return <div>No data available</div>;

  return (
    <div className="stock-screener-container">
      <h2 className="stock-screener-title">Stock Screener</h2>
      <div className="screener-filter-section">
        <FilterSection onFilter={handleFilter} />
        <FilterTags 
          filters={filters} 
          onRemove={(column, value) => handleFilter(column, value, true)} 
        />
      </div>
      <table className="stock-screener-table">
        <thead>
          <tr>
            {columns.map(column => (
              <th 
                key={column.key} 
                onClick={() => handleSort(column.key)}
                className={`${getColumnAlignment(column.key)} sortable-header`}
              >
                <div className="stock-screener-th-content">
                  {column.tooltip ? (
                    <Tooltip content={t(column.tooltip)}>
                      <span>{column.label}</span>
                    </Tooltip>
                  ) : (
                    <span>{column.label}</span>
                  )}
                  {sortConfig.key === column.key && (
                    <span className="stock-screener-sort-indicator">
                      {sortConfig.direction === 'ascending' ? '↑' : '↓'}
                    </span>
                  )}
                </div>
              </th>
            ))}
          </tr>
        </thead>
        <tbody>
          {getFilteredData().map(stock => (
            <tr key={stock.ticker}>
              <td className="stock-screener-align-left">{stock.ticker}</td>
              <td className="stock-screener-align-left">{stock.name}</td>
              <td className="stock-screener-align-right">{stock.close.toFixed(2)}</td>
              <td className="stock-screener-align-right">
                <span className={parseFloat(stock.recent_performance) >= 0 ? 'stock-screener-positive' : 'stock-screener-negative'}>
                  {stock.recent_performance}
                </span>
              </td>
              <td className="stock-screener-align-center">
                <div className="stock-screener-grade-container">
                  {renderGrade(stock.bci, 'bci')}
                </div>
              </td>
              <td className="stock-screener-align-center">
                <div className="stock-screener-grade-container">
                  {renderGrade(stock.vp, 'vp')}
                </div>
              </td>
              <td className="stock-screener-align-center">
                <div className="stock-screener-grade-container">
                  {renderGrade(stock.risk, 'risk')}
                </div>
              </td>
              <td className="stock-screener-align-center">
                <div className="stock-screener-grade-container">
                  {renderGrade(stock.sentiment_rank, 'sentiment')}
                </div>
              </td>
            </tr>
          ))}
        </tbody>
      </table>
    </div>
  );
};

export default StockScreener; 