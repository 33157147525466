import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { useSelector, useDispatch } from 'react-redux';
import PageLayout from './PageLayout';
import { PRODUCTS } from '../config';
import { createCheckoutSession, getSubscriptionInfo, activateOneDayPass, stripePromise } from '../services/stripe_api';
import './PricingPage.css';

const PricingPage = () => {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const [billingCycle, setBillingCycle] = useState('monthly');
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(null);

  const userData = useSelector(state => state.auth.userData);
  const subscription = useSelector(state => state.auth.subscription);

  const handleSubscribe = async (priceId) => {
    try {
      if (!userData?.userid) {
        navigate('/login');
        return;
      }

      setLoading(true);
      setError(null);

      const checkoutSession = await createCheckoutSession(
        priceId,
        userData.userid,
        billingCycle
      );

      const stripe = await stripePromise;
      if (!stripe) {
        throw new Error(t('errors.stripeLoadFailed'));
      }

      const { error } = await stripe.redirectToCheckout({
        sessionId: checkoutSession.id
      });

      if (error) {
        console.error('Stripe redirect error:', error);
        throw error;
      }
    } catch (err) {
      console.error('Subscribe error:', err);
      setError(t(err.message) || t('errors.paymentFailed'));
    } finally {
      setLoading(false);
    }
  };

  const handleOneDayPass = async () => {
    try {
      if (!userData?.userid) {
        navigate('/login');
        return;
      }

      setLoading(true);
      setError(null);

      await activateOneDayPass(userData.userid);
      
      const newSubscriptionData = await getSubscriptionInfo(userData.userid);
      dispatch({ 
        type: 'UPDATE_SUBSCRIPTION_DATA', 
        payload: newSubscriptionData 
      });

      navigate('/profile');
    } catch (err) {
      console.error('One-day pass activation error:', err);
      setError(t('oneDayPassError'));
    } finally {
      setLoading(false);
    }
  };

  const calculateDiscount = (monthlyPrice, yearlyPrice) => {
    if (monthlyPrice === 0 || yearlyPrice === 0) return 0;
    const monthlyTotal = monthlyPrice * 12;
    const yearlyTotal = yearlyPrice;
    const discount = ((monthlyTotal - yearlyTotal) / monthlyTotal) * 100;
    return Math.round(discount);
  };

  return (
    <PageLayout>
      <div className="pricing-page">
        <div className="content">
          <h1 className="pricing-title">
            {billingCycle === 'monthly' ? t('monthlyMemberships') : t('annualMemberships')}
          </h1>
          <p className="pricing-subtitle">{t('freeTrialInfo')}</p>

          <div className="pricing-toggle">
            <button 
              className={billingCycle === 'monthly' ? 'active' : ''} 
              onClick={() => setBillingCycle('monthly')}
            >
              {t('monthly')}
            </button>
            <button 
              className={billingCycle === 'yearly' ? 'active' : ''} 
              onClick={() => setBillingCycle('yearly')}
            >
              {t('yearly')}
            </button>
          </div>

          <div className="pricing-plans">
            {PRODUCTS.map((product) => (
              <div key={product.id} className="plan">
                <div className="plan-header">{t(product.name)}</div>
                <div className="plan-price">
                  {billingCycle === 'yearly' && (
                    <span className="original-price">
                      ${product.monthlyPrice * 12}/yr
                    </span>
                  )}
                  <span className="current-price">
                    ${billingCycle === 'monthly' ? product.monthlyPrice : product.yearlyPrice}/
                    {billingCycle === 'monthly' ? t('mo') : t('yr')}
                  </span>
                </div>
                {billingCycle === 'yearly' && (
                  <div className="discount">
                    {t('save')} {calculateDiscount(product.monthlyPrice, product.yearlyPrice)}%
                  </div>
                )}
                <div className="savings">
                  {billingCycle === 'yearly' && 
                    `${t('savings')}: $${(product.monthlyPrice * 12) - product.yearlyPrice}`
                  }
                </div>
                <button 
                  className="subscribe-button" 
                  onClick={() => handleSubscribe(
                    product[billingCycle === 'monthly' ? 'monthlyPriceId' : 'yearlyPriceId']
                  )}
                  disabled={loading}
                >
                  {loading ? t('processing') : t('subscribe')}
                </button>
              </div>
            ))}
          </div>

          <div className="free-trial-info">
            <h2>{t('freeTrialInfoTitle')}</h2>
            <p>{t('freeTrialInfoDescription')}</p>
          </div>

          <div className="one-day-pass">
            <h2>{t('oneDayPassTitle')}</h2>
            <p>{t('oneDayPassDescription')}</p>
            <button 
              className="one-day-pass-button" 
              onClick={handleOneDayPass}
              disabled={loading}
            >
              {loading ? t('processing') : t('getOneDayPass')}
            </button>
          </div>

          {error && (
            <div className="error-message" style={{ marginTop: '1rem' }}>
              {error}
            </div>
          )}
        </div>
      </div>
    </PageLayout>
  );
};

export default PricingPage;
