import React, { useState, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import ReactECharts from 'echarts-for-react';
import { getBciAHistory, getBciALatest } from '../services/data_api';
import dayjs from 'dayjs';
import './BciAHistoryChart.css';

const TIME_RANGES = [
  { label: '1M', days: 30 },
  { label: '3M', days: 90 },
  { label: '6M', days: 180 },
  { label: '1Y', days: 365 },
  { label: 'ALL', days: 0 }
];

// 定义颜色映射
const METRIC_COLORS = {
  'COIN': '#1E88E5',
  'MSTR': '#FFC107',
  'MARA': '#E53935',
  'RIOT': '#42A5F5',
  'HUT': '#4CAF50',
  'BTBT': '#FF7043',
  'HIVE': '#9C27B0'
};

// 修改格式化函数，移除单位转换
const formatValue = (value) => {
  if (typeof value !== 'number') return 'N/A';
  // 直接格式化，不需要除以1000
  return `$${value.toFixed(2)}B`;
};

const BciAHistoryChart = () => {
  const { t } = useTranslation();
  const [data, setData] = useState([]);
  const [latestData, setLatestData] = useState(null);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const [selectedRange, setSelectedRange] = useState('ALL');

  // 添加 filterDataByRange 函数
  const filterDataByRange = (data, range) => {
    if (range === 'ALL') return data;
    const cutoffDate = new Date();
    cutoffDate.setDate(cutoffDate.getDate() - TIME_RANGES.find(r => r.label === range).days);
    return data.filter(item => new Date(item.date) >= cutoffDate);
  };

  useEffect(() => {
    const fetchData = async () => {
      try {
        const [historyResponse, latestResponse] = await Promise.all([
          getBciAHistory(),
          getBciALatest()
        ]);
        
        if (historyResponse?.data) {
          setData(historyResponse.data);
        }
        
        // 处理最新数据
        if (latestResponse?.data) {
          setLatestData(latestResponse.data);
        } else if (latestResponse) {
          // 如果直接返回数据对象而不是包含在 data 属性中
          setLatestData(latestResponse);
        }
        
        setLoading(false);
      } catch (err) {
        console.error('Error fetching data:', err);
        setError(err.message);
        setLoading(false);
      }
    };

    fetchData();
  }, []);

  const getChartOption = () => {
    if (!data || data.length === 0) return {};
    
    const filteredData = filterDataByRange(data, selectedRange);
    
    return {
      backgroundColor: '#ffffff',
      tooltip: {
        trigger: 'axis',
        axisPointer: {
          type: 'cross'
        },
        formatter: function(params) {
          const date = params[0]?.axisValue || '';
          let result = `<div style="padding: 3px;">
            <div style="margin-bottom: 4px;"><strong>${date}</strong></div>`;
          
          params.forEach(param => {
            // 使用新的格式化函数
            const value = typeof param.value === 'number' ? formatValue(param.value) : 'N/A';
            result += `<div style="color: ${METRIC_COLORS[param.seriesName]}; padding: 3px 0;">
              <span style="display: inline-block; width: 10px; height: 10px; background-color: ${METRIC_COLORS[param.seriesName]}; border-radius: 50%; margin-right: 8px;"></span>
              ${param.seriesName}: ${value}
            </div>`;
          });
          
          return result + '</div>';
        }
      },
      legend: {
        data: Object.keys(METRIC_COLORS),
        top: '10%',
        left: 'center',
        textStyle: {
          fontSize: 14
        }
      },
      grid: {
        left: '5%',
        right: '5%',
        bottom: '15%',
        top: '25%',
        containLabel: true
      },
      xAxis: {
        type: 'category',
        data: filteredData.map(item => dayjs(item.date).format('YYYY-MM-DD')),
        axisLabel: {
          rotate: 45,
          interval: 'auto',  // 自动计算间隔
          showMaxLabel: true,  // 显示最后一个标签
          hideOverlap: false  // 允许重叠
        },
        splitLine: {
          show: true,
          lineStyle: {
            color: '#f0f0f0',
            type: 'dashed'
          }
        }
      },
      yAxis: {
        type: 'value',
        name: 'Value (B$)',
        nameTextStyle: {
          fontSize: 16,
          fontWeight: 500,
          padding: [0, 0, 0, 50]
        },
        axisLabel: {
          formatter: function(value) {
            // 直接格式化，不需要除以1000
            return `$${value.toFixed(1)}B`;
          },
          fontSize: 14
        },
        splitLine: {
          show: true,
          lineStyle: {
            color: '#f0f0f0',
            type: 'dashed'
          }
        }
      },
      dataZoom: [
        {
          type: 'inside',
          start: 0,
          end: 100
        },
        {
          type: 'slider',
          show: true,
          height: 30,
          bottom: 5,
          borderColor: '#f0f0f0',
          fillerColor: 'rgba(26, 115, 232, 0.1)',
          handleStyle: {
            color: '#1a73e8'
          }
        }
      ],
      series: Object.entries(METRIC_COLORS).map(([metric, color]) => ({
        name: metric,
        type: 'line',
        data: filteredData.map(item => {
          const value = item[metric.toLowerCase()];
          // 确保返回有效的数字或 null
          return typeof value === 'number' ? value : null;
        }),
        smooth: true,
        symbol: 'circle',
        symbolSize: 1,
        lineStyle: {
          width: 2,
          color: color
        },
        itemStyle: {
          color: color
        },
        connectNulls: true  // 连接空值点
      }))
    };
  };

  // 表格数据处理函数
  const getTableData = () => {
    if (!latestData) return [];
    
    return Object.entries(latestData)
      .filter(([key]) => key !== 'date')
      .sort(([, a], [, b]) => b - a)
      .map(([key, value]) => ({
        stock: key.toUpperCase(),
        value: formatValue(value)
      }));
  };

  if (loading) return <div className="loading-state">{t('loading')}</div>;
  if (error) return <div className="error-state">{t('error')}: {error}</div>;

  return (
    <div className="bcia-comparison">
      <div className="bcia-container">
        {/* 左侧表格部分 */}
        <div className="bcia-table-section">
          <div className="bcia-title-section">
            <h3>{t('Market Cap Analysis of BTC-related Stocks')}</h3>
            <div className="bcia-subtitle">(very strong relationship)</div>
            <div className="bcia-timestamp">
              {latestData?.date && dayjs(latestData.date).format('YYYY-MM-DD HH:mm:ss')}
            </div>
          </div>
          <div className="bcia-table-wrapper">
            <table className="bcia-table">
              <thead>
                <tr>
                  <th>Stock</th>
                  <th>Value</th>
                </tr>
              </thead>
              <tbody>
                {getTableData().map(({ stock, value }) => (
                  <tr key={stock}>
                    <td>{stock}</td>
                    <td>{value}</td>
                  </tr>
                ))}
              </tbody>
            </table>
          </div>
        </div>

        {/* 右侧图表部分 */}
        <div className="bcia-chart-wrapper">
          <div className="bcia-range-selector">
            {TIME_RANGES.map(range => (
              <button
                key={range.label}
                className={`bcia-range-btn ${selectedRange === range.label ? 'active' : ''}`}
                onClick={() => setSelectedRange(range.label)}
              >
                {range.label}
              </button>
            ))}
          </div>
          <div className="bcia-chart">
            <ReactECharts 
              option={getChartOption()}
              style={{ height: '100%', minHeight: '500px' }}
              notMerge={true}
            />
          </div>
        </div>
      </div>
    </div>
  );
};

export default BciAHistoryChart; 