import React from 'react';
import StockDetail from './StockDetail';
import StockScreener from './StockScreener';
import { STOCK_LIST } from '../../config/stocks';

// 动态创建所有股票组件
const StockComponents = {};

STOCK_LIST.forEach(ticker => {
  StockComponents[ticker] = () => <StockDetail ticker={ticker} />;
});

// 使用单个导出语句
export {
  StockComponents,
  StockScreener
}; 