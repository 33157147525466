import React from 'react';

const FearGreedIndex = () => {
  return (
    <div>
      <h2>Fear & Greed Index</h2>
      {/* 临时占位内容 */}
      <p>Fear & Greed Index content coming soon...</p>
    </div>
  );
};

export default FearGreedIndex; 