import React from 'react';
import { useTranslation } from 'react-i18next';
import PageLayout from './PageLayout';
import './AboutPage.css';

const AboutPage = () => {
  const { t } = useTranslation();

  const teamMembers = [
    { name: 'John Doe', position: 'Founder & CEO', image: 'https://via.placeholder.com/150' },
    { name: 'Jane Smith', position: 'Chief Technology Officer', image: 'https://via.placeholder.com/150' },
    { name: 'Emily Johnson', position: 'Chief Financial Officer', image: 'https://via.placeholder.com/150' },
  ];

  return (
    <PageLayout>
      <div className="about-page">
        <div className="content">
          <h1>{t('aboutUs')}</h1>
          <div className="section">
            <h2>{t('ourMission')}</h2>
            <p>{t('missionStatement')}</p>
          </div>
          <div className="section">
            <h2>{t('ourVision')}</h2>
            <p>{t('visionStatement')}</p>
          </div>
          <div className="section">
            <h2>{t('meetTheTeam')}</h2>
            <div className="team-container">
              {teamMembers.map((member) => (
                <div key={member.name} className="team-member">
                  <img src={member.image} alt={member.name} />
                  <h3>{member.name}</h3>
                  <p>{member.position}</p>
                </div>
              ))}
            </div>
          </div>
        </div>
      </div>
    </PageLayout>
  );
};

export default AboutPage;
