import { createStore } from 'redux';

console.log('Initializing Redux store...');

// 确保初始状态包含 auth 对象
const initialState = {
  auth: {
    userData: null,
    isLoggedIn: false,
    subscription: null
  }
};

// 安全地初始化状态
try {
  console.log('Loading initial state from localStorage...');
  const savedUserData = localStorage.getItem('userData');
  if (savedUserData) {
    initialState.auth = {
      userData: JSON.parse(savedUserData),
      isLoggedIn: localStorage.getItem('isLoggedIn') === 'true',
      subscription: JSON.parse(localStorage.getItem('subscription'))
    };
    console.log('Initial state loaded:', initialState);
  }
} catch (error) {
  console.error('Failed to load initial state:', error);
}

const authReducer = (state = initialState, action) => {
  console.log('Reducer called with action:', action.type);
  
  switch (action.type) {
    case 'SET_USER_DATA':
      console.log('Setting user data:', action.payload);
      try {
        localStorage.setItem('userData', JSON.stringify(action.payload));
        localStorage.setItem('isLoggedIn', 'true');
      } catch (error) {
        console.error('Failed to save user data:', error);
      }
      return {
        ...state,
        auth: {
          userData: action.payload,
          isLoggedIn: true,
          subscription: state.auth.subscription
        }
      };
    case 'UPDATE_SUBSCRIPTION_DATA':
      const updatedUserData = {
        ...state.auth.userData,
        subscription: {
          status: action.payload.subscription_status,
          type: action.payload.subscription_type,
          periodStart: action.payload.current_period_start,
          periodEnd: action.payload.current_period_end,
          cancelAtPeriodEnd: action.payload.cancel_at_period_end,
          isFirstTimeSubscriber: action.payload.is_first_time_subscriber,
          oneDayPassAvailable: action.payload.one_day_pass_available,
          oneDayPassUsedAt: action.payload.one_day_pass_used_at,
          firstPurchaseBonusUsed: action.payload.first_purchase_bonus_used,
          stripeSubscriptionId: action.payload.stripe_subscription_id,
          stripeCustomerId: action.payload.stripe_customer_id
        }
      };
      try {
        localStorage.setItem('userData', JSON.stringify(updatedUserData));
        localStorage.setItem('subscription', JSON.stringify(updatedUserData.subscription));
      } catch (error) {
        console.error('Failed to save subscription data:', error);
      }
      return {
        ...state,
        auth: {
          ...state.auth,
          userData: updatedUserData,
          subscription: updatedUserData.subscription
        }
      };
    case 'SET_LOGGED_OUT':
      try {
        localStorage.removeItem('userData');
        localStorage.removeItem('isLoggedIn');
        localStorage.removeItem('subscription');
      } catch (error) {
        console.error('Failed to clear localStorage:', error);
      }
      return {
        ...state,
        auth: {
          userData: null,
          isLoggedIn: false,
          subscription: null
        }
      };
    default:
      return state;
  }
};

// 创建 store
let store;
try {
  console.log('Creating Redux store...');
  store = createStore(
    authReducer,
    window.__REDUX_DEVTOOLS_EXTENSION__ && window.__REDUX_DEVTOOLS_EXTENSION__()
  );
  console.log('Store created successfully');
} catch (error) {
  console.error('Failed to create store with DevTools:', error);
  store = createStore(authReducer);
}

export default store;
