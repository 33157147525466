import React, { useState, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import { callLoginApi } from '../login_api';
import PageLayout from './PageLayout';
import './UserProfilePage.css';
import { getSubscriptionInfo } from '../services/stripe_api';

const UserProfilePage = () => {
  const { t } = useTranslation();
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const dispatch = useDispatch();
  const userData = useSelector(state => state.auth?.userData);
  const subscription = useSelector(state => state.auth?.subscription);
  const [retryCount, setRetryCount] = useState(0);
  const MAX_RETRIES = 3;
  const [subscriptionLoading, setSubscriptionLoading] = useState(false);
  const [subscriptionError, setSubscriptionError] = useState(null);

  useEffect(() => {
    loadUserData();
  }, []);

  const loadUserData = async () => {
    try {
      setLoading(true);
      console.log('Starting to load user data...');
      
      // 1. 加载用户数据
      const userInfo = await callLoginApi('/load-user');
      console.log('Received user info:', userInfo);
      
      if (!userInfo || !userInfo.userid) {
        throw new Error('Invalid user data received');
      }

      // 先更新用户基本信息
      dispatch({ 
        type: 'SET_USER_DATA', 
        payload: userInfo
      });
      
      // 2. 加载订阅数据（独立处理）
      setSubscriptionLoading(true);
      try {
        const subscriptionData = await getSubscriptionInfo(userInfo.userid);
        console.log('Received subscription data:', subscriptionData);
        
        // 更新订阅信息
        dispatch({ 
          type: 'UPDATE_SUBSCRIPTION_DATA', 
          payload: subscriptionData
        });
        setSubscriptionError(null);
      } catch (subError) {
        console.error('Subscription fetch error:', subError);
        setSubscriptionError(subError.message);
      } finally {
        setSubscriptionLoading(false);
      }
      
      setError(null);
    } catch (error) {
      console.error('Failed to load user data:', error);
      setError(error.message || 'Failed to load user data');
    } finally {
      setLoading(false);
    }
  };

  // 在组件卸载时取消所有未完成的请求
  useEffect(() => {
    const controller = new AbortController();
    return () => {
      controller.abort();
    };
  }, []);

  const renderSubscriptionInfo = () => {
    if (!subscription) {
      return <div className="no-subscription">{t('noSubscriptionInfo')}</div>;
    }

    return (
      <div className="subscription-details">
        <div className="subscription-header">
          <div className="title-section">
            <h2>{t('subscriptionDetails')}</h2>
            <div className="subscription-type">
              {t('currentPlan')}: {t(`subscription.type.${subscription.type}`)}
            </div>
          </div>
          <span className={`subscription-status status-${subscription.status}`}>
            {t(`subscription.status.${subscription.status}`)}
          </span>
        </div>

        <div className="subscription-content">
          {subscription.status === 'active' && (
            <div className="subscription-period">
              <div className="period-item">
                <span className="period-label">{t('periodStart')}:</span>
                <span className="period-value">{formatDate(subscription.periodStart)}</span>
              </div>
              <div className="period-item">
                <span className="period-label">{t('periodEnd')}:</span>
                <span className="period-value">{formatDate(subscription.periodEnd)}</span>
              </div>
            </div>
          )}

          <div className="subscription-features">
            <h4>{t('includedFeatures')}</h4>
            <div className="features-list">
              <div className="feature-item">
                <svg width="20" height="20" viewBox="0 0 20 20" fill="none">
                  <path d="M16.7 5.3l-8 8-4.7-4.7 1.4-1.4 3.3 3.3 6.6-6.6 1.4 1.4z" fill="currentColor"/>
                </svg>
                <span>{t('premiumContent')}</span>
              </div>
              <div className="feature-item">
                <svg width="20" height="20" viewBox="0 0 20 20" fill="none">
                  <path d="M16.7 5.3l-8 8-4.7-4.7 1.4-1.4 3.3 3.3 6.6-6.6 1.4 1.4z" fill="currentColor"/>
                </svg>
                <span>{t('marketAnalysis')}</span>
              </div>
              <div className="feature-item">
                <svg width="20" height="20" viewBox="0 0 20 20" fill="none">
                  <path d="M16.7 5.3l-8 8-4.7-4.7 1.4-1.4 3.3 3.3 6.6-6.6 1.4 1.4z" fill="currentColor"/>
                </svg>
                <span>{t('prioritySupport')}</span>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  };

  if (loading) {
    return (
      <PageLayout>
        <div className="loading">{t('loading')}...</div>
      </PageLayout>
    );
  }

  if (error) {
    return (
      <PageLayout>
        <div className="error-message">{t('failedToLoadUserData')}: {error}</div>
      </PageLayout>
    );
  }

  if (!userData) {
    return (
      <PageLayout>
        <div className="error-message">{t('noUserData')}</div>
      </PageLayout>
    );
  }

  // 获取用户名首字母作为头像显示
  const getInitials = () => {
    return userData.firstName ? userData.firstName[0].toUpperCase() : '?';
  };

  const formatDate = (dateString) => {
    return new Date(dateString).toLocaleDateString();
  };

  return (
    <PageLayout>
      <div className="user-profile-page">
        <div className="profile-grid">
          {/* 用户信息卡片 */}
          <div className="user-info-card">
            <div className="user-avatar">
              {getInitials()}
            </div>
            <div className="user-info">
              <h2>{`${userData.firstName} ${userData.lastName}`}</h2>
              <div className="label">{t('email')}</div>
              <p>{userData.username}</p>
              <div className="label">{t('memberSince')}</div>
              <p>{new Date().toLocaleDateString()}</p>
            </div>
          </div>

          {/* 订阅信息区域 */}
          <div className="subscription-section">
            {renderSubscriptionInfo()}
          </div>
        </div>
      </div>
    </PageLayout>
  );
};

export default UserProfilePage;
