export const PRODUCTS = [
  {
    id: 'prod_1',
    name: 'basic',
    description: 'toolsInBasic',
    monthlyPrice: 0,
    yearlyPrice: 0,
    features: ['btcStocksScreener', 'calendarMoves', 'fundamentalCharts']
  },
  {
    id: 'prod_2',
    name: 'pro',
    description: 'keyBenefitsOfPro',
    monthlyPriceId: 'price_1PsgVRBR55EZVoQVfvmtWlKD',
    yearlyPriceId: 'price_1PsgVwBR55EZVoQVSdQQThBy',
    monthlyPrice: 20.00,
    yearlyPrice: 200.00,
    features: ['livePickedStockScreener', 'instantInsights', 'comparisonWithKeyAssets']
  },
  // {
  //   id: 'prod_3',
  //   name: 'proPlus',
  //   description: 'everythingInProPlus',
  //   monthlyPriceId: 'price_1PsgX3BR55EZVoQVwXdMAs9e',
  //   yearlyPriceId: 'price_1PsgXTBR55EZVoQVIVjWDCAG',
  //   monthlyPrice: 30.00,
  //   yearlyPrice: 300.00,
  //   features: ['advancedPickedStockScreener', 'realTimeMarketAnalysis', 'customizableMetricViews']
  // }
];

// 如果有其他配置，也可以在这里添加
