import React from 'react';
import { useNavigate, useLocation } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import PageLayout from './PageLayout';
import './PaymentFailurePage.css';

const PaymentFailurePage = () => {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const location = useLocation();
  const error = location.state?.error;

  return (
    <PageLayout>
      <div className="payment-result-page failure">
        <div className="failure-container">
          <div className="failure-icon">✕</div>
          <h2>{t('paymentFailed')}</h2>
          <p>{error ? t(error) : t('paymentFailedMessage')}</p>
          <div className="action-buttons">
            <button 
              className="primary" 
              onClick={() => navigate('/pricing')}
            >
              {t('tryAgain')}
            </button>
            <button 
              className="secondary" 
              onClick={() => navigate('/profile')}
            >
              {t('goToProfile')}
            </button>
          </div>
        </div>
      </div>
    </PageLayout>
  );
};

export default PaymentFailurePage; 