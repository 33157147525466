import React from 'react';

const MvrvZ = () => {
  return (
    <div>
      <h2>MVRV-Z Value</h2>
      {/* 临时占位内容 */}
      <p>MVRV-Z value content coming soon...</p>
    </div>
  );
};

export default MvrvZ; 