export const STOCKS = {
  MSTR: {
    name: 'MicroStrategy Incorporated',
    sector: 'Technology',
    introduction: 'MicroStrategy Incorporated provides artificial intelligence-powered enterprise analytics software and services in the United States and internationally.'
  },
  BITF: {
    name: 'Bitfarms Ltd.',
    sector: 'Technology',
    introduction: 'Bitfarms is a global Bitcoin self-mining company, running vertically integrated mining operations.'
  },
  COIN: {
    name: 'Coinbase Global Inc.',
    sector: 'Financial Services',
    introduction: 'Coinbase is a cryptocurrency exchange platform that allows users to buy, sell, and store various cryptocurrencies.'
  },
  // ... 其他股票配置
};

// 所有支持的股票列表
export const STOCK_LIST = [
  'COIN', 'CLSK', 'HOOD', 'HUT', 'MA', 'CIFR', 'BLK', 'NVDA', 'GLOB', 'FRMO',
  'BITF', 'BYON', 'MSTR', 'MARA', 'CORZ', 'TSLA', 'RDDT', 'CME', 'CAN', 'SQ',
  'EXOD', 'HIVE', 'PYPL', 'MELI', 'BTBT', 'RIOT', 'SMLR', 'WULF', 'IREN', 'BTDR',
  'SOFI', 'AMD', 'V', 'SHOP', 'META'
]; 