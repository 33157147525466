import React, { useState, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import PageLayout from './PageLayout';
import './ProductsPage.css';
import FearGreedMeter from './FearGreedMeter';
import { getBtcOverview, getStockOverview } from '../services/data_api';
import Tooltip from './Tooltip';
import QASection from './QASection';
import Overview from './Overview';
import LongTermOverview from './LongTermOverview';
import MarketCap from './MarketCap';
import EtfNetInflow from './EtfNetInflow';
import FearGreedIndex from './FearGreedIndex';
import MvrvZ from './MvrvZ';
import { StockComponents, StockScreener } from './btc-stocks';
import { STOCK_LIST } from '../config/stocks';

const InfoCard = ({ onClick }) => {
  const { t } = useTranslation();
  
  return (
    <div className="info-card" onClick={onClick}>
      <div className="card-image-container">
        <img src="/images/WhyBTC.jpg" alt="" className="card-image" />
      </div>
      <div className="card-content">
        <h3 className="card-title">{t('btcOpportunities.title')}</h3>
        <ul className="bullet-points">
          {t('btcOpportunities.bulletPoints', { returnObjects: true }).map((point, index) => (
            <li key={index} className="bullet-point">{point}</li>
          ))}
        </ul>
      </div>
    </div>
  );
};

const Modal = ({ isOpen, onClose }) => {
  if (!isOpen) return null;

  return (
    <div className="modal-overlay" onClick={onClose}>
      <div className="modal-content" onClick={e => e.stopPropagation()}>
        <button className="modal-close" onClick={onClose}>×</button>
        <iframe 
          className="modal-iframe"
          src="/Education Article/Education Article/education article.html"
          title="Why BTC"
          allowFullScreen
        />
      </div>
    </div>
  );
};

const FilterTags = ({ filters, onRemove }) => {
  return (
    <div className="filter-tags">
      {Object.entries(filters).map(([column, values]) =>
        values.map(value => (
          <span key={`${column}-${value}`} className="filter-tag">
            {`${column}: ${value}`}
            <button 
              className="remove-tag" 
              onClick={() => onRemove(column, value)}
            >
              ×
            </button>
          </span>
        ))
      )}
    </div>
  );
};

const FilterSection = ({ onFilter }) => {
  const filterTypes = [
    { key: 'bci', label: 'BTC Correlation Index' },
    { key: 'vp', label: 'Valuation Premium' },
    { key: 'risk', label: 'Risk' },
    { key: 'sentiment_rank', label: 'Sentiment' }
  ];

  return (
    <div className="filter-section">
      <div className="filter-controls">
        {filterTypes.map(type => (
          <div key={type.key} className="filter-group">
            <label>{type.label}</label>
            <input
              type="text"
              className="filter-input"
              placeholder={`Filter ${type.label}...`}
              onKeyPress={(e) => {
                if (e.key === 'Enter' && e.target.value.trim()) {
                  onFilter(type.key, e.target.value.toLowerCase());
                  e.target.value = '';
                }
              }}
            />
          </div>
        ))}
      </div>
    </div>
  );
};

const ProductsPage = () => {
  const { t } = useTranslation();
  const [activeCategory, setActiveCategory] = useState('overview');
  const [expandedItems, setExpandedItems] = useState(['btc']);
  const [btcData, setBtcData] = useState(null);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const [stockData, setStockData] = useState(null);
  const [stockLoading, setStockLoading] = useState(true);
  const [stockError, setStockError] = useState(null);
  const [sortConfig, setSortConfig] = useState({
    key: null,
    direction: 'ascending'
  });
  const [modalOpen, setModalOpen] = useState(false);
  const [filters, setFilters] = useState({
    bci: [],
    vp: [],
    risk: [],
    sentiment_rank: []
  });

  const columns = [
    { key: 'ticker', label: 'Ticker' },
    { key: 'name', label: 'Name' },
    { key: 'close', label: 'Close' },
    { 
      key: 'recent_performance', 
      label: 'Recent Performance',
      tooltip: 'tooltips.recentPerformance',
      render: (value) => (
        <div className="th-content">
          <span className={parseFloat(value) >= 0 ? 'positive' : 'negative'}>
            {value}
          </span>
        </div>
      )
    },
    { 
      key: 'bci', 
      label: 'BTC Correlation Index (BCI)', 
      tooltip: 'tooltips.bci',
      render: (value) => renderGrade(value, 'bci')
    },
    { 
      key: 'vp', 
      label: 'Valuation Premium (VP)', 
      tooltip: 'tooltips.vp',
      render: (value) => renderGrade(value, 'vp')
    },
    { 
      key: 'risk', 
      label: 'Risk', 
      tooltip: 'tooltips.risk',
      render: (value) => renderGrade(value, 'risk')
    },
    { 
      key: 'sentiment_rank', 
      label: 'Sentiment rank', 
      tooltip: 'tooltips.sentiment',
      render: (value) => renderGrade(value, 'sentiment')
    }
  ];

  const sortData = (data, key) => {
    return [...data].sort((a, b) => {
      if (key === 'close' || key === 'return') {
        return parseFloat(a[key]) - parseFloat(b[key]);
      }
      if (['bci', 'vp', 'risk', 'sentiment'].includes(key)) {
        return a[key].localeCompare(b[key]);
      }
      return a[key].toString().localeCompare(b[key].toString());
    });
  };

  const handleSort = (key) => {
    let direction = 'ascending';
    if (sortConfig.key === key && sortConfig.direction === 'ascending') {
      direction = 'descending';
    }
    setSortConfig({ key, direction });
  };

  const getSortedData = () => {
    if (!sortConfig.key || !stockData?.data) return stockData?.data || [];
    const sortedData = sortData(stockData.data, sortConfig.key);
    return sortConfig.direction === 'descending' ? sortedData.reverse() : sortedData;
  };

  const getDescription = (column, value) => {
    if (!value) return '';
    
    if (column === 'sentiment_rank') {
      return value === 'A' ? 'very bullish' :
             value === 'B' ? 'bullish' :
             value === 'C' ? 'neutral' :
             value === 'D' ? 'bearish' :
             value === 'E' ? 'very bearish' : value.toString().toLowerCase();
    }
    const descriptions = {
      bci: {
        'A': 'very strong',
        'B': 'strong',
        'C': 'medium',
        'D': 'weak',
        'E': 'very weak'
      },
      vp: {
        'A': 'very high',
        'B': 'high',
        'C': 'medium',
        'D': 'low',
        'E': 'very low'
      },
      risk: {
        'A': 'very high',
        'B': 'high',
        'C': 'medium',
        'D': 'low',
        'E': 'very low'
      },
      sentiment: {
        'A': 'very bullish',
        'B': 'bullish',
        'C': 'medium',
        'D': 'bearish',
        'E': 'very bearish'
      }
    };
    return descriptions[column]?.[value] || '';
  };

  const renderGrade = (grade, type) => {
    if (!grade) return '-';
    
    const getGradeColor = (grade, type) => {
      if (type === 'risk') {
        const riskColors = {
          'A': '#D32F2F', // very high risk - 调整为更深的红色
          'B': '#F57C00', // high risk - 调整为更深的橙色
          'C': '#FDD835', // medium risk - 黄色
          'D': '#388E3C', // low risk - 调为更深的绿色
          'E': '#1B5E20'  // very low risk - 深绿
        };
        return riskColors[grade] || '#ddd';
      }
      
      const colors = {
        'A': '#388E3C',  // 更新为新的绿色
        'B': '#388E3C',  // 保持不变
        'C': '#FDD835',  // 黄色
        'D': '#F57C00',  // 橙色
        'E': '#D32F2F'   // 红色
      };
      return colors[grade] || '#ddd';
    };

    const description = getDescription(type, grade);
    
    return (
      <div className="grade-container">
        {['bci', 'sentiment'].includes(type) && (
          <div className="horizontal-indicator">
            <div className="marker" style={{left: 
              grade === 'A' ? '100%' : 
              grade === 'B' ? '75%' : 
              grade === 'C' ? '50%' : 
              grade === 'D' ? '25%' : '0%'
            }}></div>
          </div>
        )}
        {['vp', 'risk'].includes(type) && (
          <div className="stacked-bars">
            {[...Array(5)].map((_, i) => (
              <div
                key={i}
                className="bar"
                style={{
                  backgroundColor: i < (
                    grade === 'A' ? 5 :
                    grade === 'B' ? 4 :
                    grade === 'C' ? 3 :
                    grade === 'D' ? 2 : 1
                  ) ? getGradeColor(grade, type) : '#ddd'
                }}
              />
            ))}
          </div>
        )}
        <span className="grade-description">{description}</span>
      </div>
    );
  };

  const renderDailyPicks = () => {
    if (stockLoading) return <div>Loading...</div>;
    if (stockError) return <div>Error: {stockError}</div>;
    if (!stockData?.data) return <div>No data available</div>;

    return (
      <div className="daily-picks">
        <h2>Daily Picks</h2>
        <FilterSection onFilter={handleFilter} />
        <FilterTags 
          filters={filters} 
          onRemove={(column, value) => handleFilter(column, value, true)} 
        />
        <table className="stocks-table">
          {renderTableHeader()}
          <tbody>
            {getFilteredData().map(stock => (
              <tr key={stock.ticker}>
                <td className="align-left">{stock.ticker}</td>
                <td className="align-left">{stock.name}</td>
                <td className="align-center">{stock.close.toFixed(2)}</td>
                <td className="align-center">
                  <div className="th-content">
                    <span className={parseFloat(stock.recent_performance) >= 0 ? 'positive' : 'negative'}>
                      {stock.recent_performance}
                    </span>
                  </div>
                </td>
                <td>
                  <div className="grade-container">
                    {renderGrade(stock.bci, 'bci')}
                  </div>
                </td>
                <td>
                  <div className="grade-container">
                    {renderGrade(stock.vp, 'vp')}
                  </div>
                </td>
                <td>
                  <div className="grade-container">
                    {renderGrade(stock.risk, 'risk')}
                  </div>
                </td>
                <td>
                  <div className="grade-container">
                    {renderGrade(stock.sentiment_rank, 'sentiment')}
                  </div>
                </td>
              </tr>
            ))}
          </tbody>
        </table>
      </div>
    );
  };

  useEffect(() => {
    const fetchBtcData = async () => {
      try {
        const data = await getBtcOverview();
        setBtcData(data);
        setLoading(false);
      } catch (err) {
        setError(err.message);
        setLoading(false);
      }
    };

    fetchBtcData();
  }, []);

  useEffect(() => {
    const fetchStockData = async () => {
      try {
        const data = await getStockOverview();
        setStockData(data);
        setStockLoading(false);
      } catch (err) {
        setStockError(err.message);
        setStockLoading(false);
      }
    };

    fetchStockData();
  }, []);

  const navigationItems = [
    { 
      id: 'overview',
      label: 'BTC Overview',
      type: 'page'
    },
    {
      id: 'btc',
      label: 'BTC',
      type: 'group',
      children: [
        { id: 'btc-overview', label: 'Overview' },
        { id: 'market-cap', label: 'Market Cap' },
        { id: 'etf-net-inflow', label: 'ETF Net Inflow' }
      ]
    },
    {
      id: 'btc-stocks',
      label: 'BTC-related stocks',
      type: 'group',
      children: [
        { id: 'stock-screener', label: 'Stock Screener' },
        { 
          id: 'small-cap',
          label: 'Small Market Cap',
          type: 'expandable',
          stocks: [
            'BYON', 'SMLR', 'FRMO', 'CAN', 'HIVE', 'EXOD', 'BTBT', 'BITF', 'BTDR',
            'HUT', 'IREN', 'CIFR', 'WULF', 'CLSK', 'CORZ', 'RIOT', 'MARA'
          ]
        },
        { 
          id: 'medium-cap',
          label: 'Medium Market Cap',
          type: 'expandable',
          stocks: [
            'GLOB', 'SOFI', 'RDDT', 'HOOD', 'SQ', 'MSTR', 'COIN', 'CME', 'PYPL'
          ]
        },
        { 
          id: 'large-cap',
          label: 'Large Market Cap',
          type: 'expandable',
          stocks: [
            'SHOP', 'MELI', 'BLK', 'AMD', 'MA', 'V', 'TSLA', 'META', 'NVDA'
          ]
        }
      ]
    }
  ];

  const toggleExpand = (itemId) => {
    setExpandedItems(prev => 
      prev.includes(itemId) 
        ? prev.filter(id => id !== itemId)
        : [...prev, itemId]
    );
  };

  const getReturnColor = (returnStr) => {
    const value = parseFloat(returnStr.replace(/[^-\d.]/g, ''));
    
    if (value >= 3) return '#1B5E20';      // 深绿色
    if (value >= 1) return '#2E7D32';      // 浅绿色
    if (value > -1) return '#F57F17';      // 深黄色
    if (value > -3) return '#D32F2F';      // 浅色
    return '#B71C1C';                      // 深色
  };

  const getMVRVColor = (value) => {
    if (value <= 3) return '#2E7D32';      // 绿色
    if (value <= 6) return '#F57F17';      // 深黄色
    return '#D32F2F';                      // 红色
  };

  const getMarketAssessment = (fearGreedIndex, mvrvZScore) => {
    const fgNormalized = fearGreedIndex / 100;
    const mvrvNormalized = mvrvZScore / 10;
    const combinedIndicator = (fgNormalized + mvrvNormalized) / 2;

    if (combinedIndicator < 0.3) {
      return 'marketAssessment.highPotential';
    } else if (combinedIndicator < 0.7) {
      return 'marketAssessment.moderate';
    } else {
      return 'marketAssessment.highRisk';
    }
  };

  // 用于渲染概览内容的辅助函数，暂时保留供后续使用
  const renderOverviewContent = () => {
    if (loading) return <div>Loading...</div>;
    if (error) return <div>Error: {error}</div>;
    if (!btcData) return <div>No data available</div>;

    // 月份转换函数
    const getMonthName = (monthNumber) => {
      const months = [
        'January', 'February', 'March', 'April',
        'May', 'June', 'July', 'August',
        'September', 'October', 'November', 'December'
      ];
      return months[monthNumber - 1];
    };

    return (
      <div className="overview-content">
        <div className="btc-header">
          <div className="btc-info">
            <div className="btc-title">
              <img src="/images/Bitcoinlogo.png" alt="BTC" className="btc-logo" />
              <h1>Bitcoin. (BTC)</h1>
            </div>
            <div className="price-info">
              <span className="label">{t('currentPrice')}:</span>
              <span className="value">${btcData.price}</span>
              <span 
                className="change"
                style={{ color: getReturnColor(btcData.daily_return) }}
              >
                ({btcData.daily_return})
              </span>
            </div>
            <div className="market-cap">
              <Tooltip content={t('tooltips.marketCap.btc')}>
                <span className="label">Market Cap:</span>
              </Tooltip>
              <span className="value">${btcData.market_cap_T}</span>
            </div>
            <div className="mvrv-z">
              <Tooltip content={t('tooltips.mrvzScore')}>
                <span className="label">MVRV-Z value:</span>
              </Tooltip>
              <span 
                className="value" 
                style={{ color: getMVRVColor(btcData.mvrv_z_score) }}
              >
                {btcData.mvrv_z_score}
              </span>
            </div>
            <div className="seasonality">
              <Tooltip content={t('tooltips.seasonality')}>
                <span className="label">Seasonality: {getMonthName(btcData.current_month)}</span>
              </Tooltip>
              <span className="value-tag">{btcData.monthly_mean}</span>
            </div>
            <div className="risk-assessment">
              <p>{t(getMarketAssessment(btcData.fear_greed_index, btcData.mvrv_z_score))}</p>
            </div>
          </div>
          <div className="fear-greed-meter-container">
            <FearGreedMeter value={btcData.fear_greed_index} />
          </div>
        </div>

        <InfoCard
          title="How to seize BTC market opportunities through US Stocks?"
          description="Why BTC? Why US Stocks? Our Strength, How to use our paid functions?"
          imageUrl="/images/whyBTC.jpg"
          onClick={() => handleCardClick()}
        />

        {renderDailyPicks()}
      </div>
    );
  };

  const renderContent = () => {
    // 如果是股票页面
    if (STOCK_LIST.includes(activeCategory.toUpperCase())) {
      const StockComponent = StockComponents[activeCategory.toUpperCase()];
      return <StockComponent />;
    }

    // 其他页面的渲染逻辑
    switch (activeCategory) {
      case 'overview':  // BTC Overview page
        return (
          <>
            {renderOverviewContent()}
            <QASection />
          </>
        );
      
      case 'btc-overview':  // Overview under BTC group
        return <Overview />;
        
      case 'market-cap':
        return <MarketCap />;
        
      case 'fear-greed-index':
        return <FearGreedIndex />;
        
      case 'mvrv-z':
        return <MvrvZ />;
        
      case 'etf-net-inflow':
        return <EtfNetInflow />;
        
      case 'long-term-overview':
        return <LongTermOverview />;
        
      case 'stock-screener':
        return <StockScreener />;
        
      default:
        return <Overview />;
    }
  };

  const renderTableHeader = () => {
    return (
      <tr>
        {columns.map(column => (
          <th 
            key={column.key} 
            onClick={() => handleSort(column.key)}
            className={`sortable-header ${getColumnAlignment(column.key)}`}
          >
            <div className="th-content">
              {column.tooltip ? (
                <Tooltip content={t(column.tooltip)}>
                  <span>{column.label}</span>
                </Tooltip>
              ) : (
                <span>{column.label}</span>
              )}
              {sortConfig.key === column.key && (
                <span className="sort-indicator">
                  {sortConfig.direction === 'ascending' ? '↑' : '↓'}
                </span>
              )}
            </div>
          </th>
        ))}
      </tr>
    );
  };

  // 辅助函数来确定列的对齐方式
  const getColumnAlignment = (key) => {
    if (['ticker', 'name'].includes(key)) {
      return 'align-left';
    }
    if (['close', 'recent_performance', 'bci', 'vp', 'risk', 'sentiment_rank'].includes(key)) {
      return 'align-center';
    }
    return 'align-center';
  };

  const handleCardClick = () => {
    setModalOpen(true);
  };

  const handleFilter = (column, value, isRemove = false) => {
    if (!value.trim()) return;
    
    const trimmedValue = value.trim().toLowerCase();
    
    setFilters(prev => {
      if (isRemove) {
        return {
          ...prev,
          [column]: prev[column].filter(item => item !== trimmedValue)
        };
      }
      
      if (prev[column].includes(trimmedValue)) {
        return prev;
      }
      
      return {
        ...prev,
        [column]: [...prev[column], trimmedValue]
      };
    });
  };

  const getFilteredData = () => {
    let data = getSortedData();
    
    Object.entries(filters).forEach(([column, values]) => {
      if (values.length > 0) {
        data = data.filter(item => {
          const itemValue = getDescription(column, item[column]).toLowerCase();
          return values.some(filterValue => 
            itemValue === filterValue.toLowerCase()
          );
        });
      }
    });
    
    return data;
  };

  return (
    <>
      <PageLayout>
        <div className="products-container">
          <aside className="products-sidebar">
            <nav className="sidebar-nav">
              {navigationItems.map(item => (
                <div key={item.id} className="nav-item-container">
                  <button
                    className={`sidebar-item ${activeCategory === item.id ? 'active' : ''} ${item.type === 'group' ? 'group' : ''}`}
                    onClick={() => item.type === 'group' ? toggleExpand(item.id) : setActiveCategory(item.id)}
                    aria-expanded={expandedItems.includes(item.id)}
                  >
                    <span className="item-content">
                      {item.label}
                    </span>
                    {(item.type === 'group' || item.type === 'expandable') && (
                      <span className={`expand-icon ${expandedItems.includes(item.id) ? 'expanded' : ''}`}>▼</span>
                    )}
                  </button>
                  {item.type === 'group' && expandedItems.includes(item.id) && (
                    <div className="sub-items">
                      {item.children.map(child => {
                        if (child.type === 'expandable') {
                          // 处理可展开的股票列表
                          return (
                            <div key={child.id} className="market-cap-category">
                              <button
                                className={`sidebar-item ${activeCategory === child.id ? 'active' : ''}`}
                                onClick={() => toggleExpand(child.id)}
                              >
                                <span className="item-content">{child.label}</span>
                                <span className={`expand-icon ${expandedItems.includes(child.id) ? 'expanded' : ''}`}>▼</span>
                              </button>
                              {expandedItems.includes(child.id) && (
                                <div className="stock-list">
                                  {child.stocks.map(stock => (
                                    <button
                                      key={stock}
                                      className={`sidebar-item stock-item ${activeCategory === stock.toLowerCase() ? 'active' : ''}`}
                                      onClick={() => setActiveCategory(stock.toLowerCase())}
                                    >
                                      {stock}
                                    </button>
                                  ))}
                                </div>
                              )}
                            </div>
                          );
                        } else {
                          // 处理普通的子项
                          return (
                            <button
                              key={child.id}
                              className={`sidebar-item ${activeCategory === child.id ? 'active' : ''}`}
                              onClick={() => setActiveCategory(child.id)}
                            >
                              {child.label}
                            </button>
                          );
                        }
                      })}
                    </div>
                  )}
                </div>
              ))}
            </nav>
          </aside>
          <main className="products-main-content">
            {renderContent()}
          </main>
        </div>
      </PageLayout>
      <Modal 
        isOpen={modalOpen}
        onClose={() => setModalOpen(false)}
      />
    </>
  );
};

export default ProductsPage; 
 