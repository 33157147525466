import React, { useState, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { getTotalMarketCapsUpdate } from '../services/data_api';
import { Pie } from 'react-chartjs-2';
import { Chart as ChartJS, ArcElement, Tooltip, Legend } from 'chart.js';
import './MarketCap.css';
import BciAHistoryChart from './BciAHistoryChart';
import TopAssetsComparison from './TopAssetsComparison';

ChartJS.register(ArcElement, Tooltip, Legend);

const MarketCap = () => {
  const { t } = useTranslation();
  const [marketCaps, setMarketCaps] = useState([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);

  // 计算饼图数据，排除 Bitcoin
  const calculateChartData = (data) => {
    const filteredData = data.filter(item => item.category !== 'Bitcoin');
    const total = filteredData.reduce((sum, item) => sum + item.market_cap_t, 0);
    
    // 确保数据按照特定顺序排序
    const orderedData = [
      ...filteredData.filter(item => item.category === 'Cryptocurrencies'),
      ...filteredData.filter(item => item.category === 'Gold'),
      ...filteredData.filter(item => item.category === 'ETFs'),
      ...filteredData.filter(item => item.category === 'Companies'),
    ];
    
    return {
      labels: orderedData.map(item => t(item.category)),
      datasets: [{
        data: orderedData.map(item => ((item.market_cap_t / total) * 100).toFixed(2)),
        backgroundColor: [
          '#6B8CEF', // Cryptocurrencies - 柔和的蓝色
          '#E5B567', // Gold - 温暖的金色
          '#FF7F50', // ETFs - 珊瑚色
          '#50C878', // Companies - 翡翠绿
        ],
        borderWidth: 0,
        hoverOffset: 4
      }]
    };
  };

  const chartOptions = {
    plugins: {
      legend: {
        position: 'left',
        align: 'center',
        labels: {
          boxWidth: 16,
          padding: 20,
          font: {
            size: 14,
            weight: 500
          },
          color: '#1a1a1a',
          generateLabels: (chart) => {
            const data = chart.data;
            return data.labels.map((label, index) => ({
              text: `${label} (${data.datasets[0].data[index]}%)`,
              fillStyle: data.datasets[0].backgroundColor[index],
              index: index
            }));
          }
        }
      },
      tooltip: {
        callbacks: {
          label: (context) => {
            return `${context.label}: ${context.raw}%`;
          }
        },
        backgroundColor: 'rgba(255, 255, 255, 0.95)',
        titleColor: '#1a1a1a',
        bodyColor: '#1a1a1a',
        borderColor: '#e0e0e0',
        borderWidth: 1,
        padding: 12,
        boxPadding: 6,
        usePointStyle: true
      }
    },
    maintainAspectRatio: false,
    layout: {
      padding: {
        left: 20,
        right: 20,
        top: 20,
        bottom: 20
      }
    },
    cutout: '50%' // 设置饼图中心孔的大小
  };

  useEffect(() => {
    const fetchData = async () => {
      try {
        const capsResponse = await getTotalMarketCapsUpdate();
        const orderedData = [
          { category: t('Bitcoin'), market_cap_t: 1.342, bitcoin_share: 1 },
          ...capsResponse.data.filter(item => item.category === 'Cryptocurrencies'),
          ...capsResponse.data.filter(item => item.category === 'Gold'),
          ...capsResponse.data.filter(item => item.category === 'ETFs'),
          ...capsResponse.data.filter(item => item.category === 'Companies'),
        ];
        setMarketCaps(orderedData);
        setLoading(false);
      } catch (err) {
        setError(err.message);
        setLoading(false);
      }
    };

    fetchData();
  }, [t]);

  if (loading) return <div>{t('loading')}</div>;
  if (error) return <div>{t('error')}: {error}</div>;

  return (
    <div className="market-cap-component">
      <div className="market-overview-section">
        <div className="overview-btc-header">
          <div className="overview-btc-title">
            <img src="/images/Bitcoinlogo.png" alt="BTC" className="overview-btc-logo" />
            <h1>{t('marketCap')}</h1>
          </div>
        </div>

        <div className="market-metrics-grid">
          <div className="metrics-row">
            <div className="metric-label">{t('marketCap')}</div>
            {marketCaps.map(item => (
              <div key={`cat-${item.id}`} className="metric-value category">
                {t(item.category)}
              </div>
            ))}
          </div>
          
          <div className="metrics-row">
            <div className="metric-label">$ (T)</div>
            {marketCaps.map(item => (
              <div key={`val-${item.id}`} className="metric-value market-value">
                {item.market_cap_t.toFixed(3)}
              </div>
            ))}
          </div>

          <div className="metrics-row">
            <div className="metric-label">{t('btcOtherCategoriesRatio')}</div>
            {marketCaps.map(item => (
              <div 
                key={`pct-${item.id}`} 
                className={`metric-value percentage ${item.category === 'Bitcoin' ? 'dash' : ''}`}
              >
                {item.category === 'Bitcoin' ? '-' : `${(item.bitcoin_share * 100).toFixed(2)}%`}
              </div>
            ))}
          </div>
        </div>
      </div>

      <div className="market-insights-section">
        <div className="market-insights-container">
          <div className="market-chart">
            <div className="pie-chart-container">
              {marketCaps.length > 0 && (
                <Pie data={calculateChartData(marketCaps)} options={chartOptions} />
              )}
            </div>
          </div>
          <div className="market-insights-list">
            <div className="insight-card">
              <h3>{t('marketCapInsights.section1.title')}</h3>
              <p>{t('marketCapInsights.section1.content')}</p>
            </div>
            <div className="insight-card">
              <h3>{t('marketCapInsights.section2.title')}</h3>
              <p>{t('marketCapInsights.section2.content')}</p>
            </div>
            <div className="insight-card">
              <h3>{t('marketCapInsights.section3.title')}</h3>
              <p>{t('marketCapInsights.section3.content')}</p>
            </div>
          </div>
        </div>
      </div>

      <div className="top-assets-section">
        <TopAssetsComparison />
      </div>

      <section className="bcia-section">
        <BciAHistoryChart />
      </section>
    </div>
  );
};

export default MarketCap; 