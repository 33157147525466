import React, { useState, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate, Link } from 'react-router-dom';
import { callLoginApi } from '../login_api';
import { getSubscriptionInfo } from '../services/stripe_api';
import './LoginPage.css';

const LoginPage = () => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const isLoggedIn = useSelector(state => state.auth?.isLoggedIn || false);
  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');
  const [message, setMessage] = useState('');

  useEffect(() => {
    if (isLoggedIn) {
      navigate('/profile');
    }
  }, [isLoggedIn, navigate]);

  const handleSubmit = async (e) => {
    e.preventDefault();
    try {
      setMessage('');
      
      // 1. 登录验证
      const loginResult = await callLoginApi('/login', { username: email, password });
      
      if (loginResult.message === 'Login successful') {
        try {
          // 2. 获取用户详细信息
          const userInfo = await callLoginApi('/load-user');
          
          if (!userInfo || !userInfo.userid) {
            throw new Error('Invalid user data received');
          }

          // 3. 获取订阅信息
          let subscriptionData = null;
          try {
            subscriptionData = await getSubscriptionInfo(userInfo.userid);
          } catch (subscriptionError) {
            console.error('Failed to fetch subscription:', subscriptionError);
          }

          // 4. 更新 Redux 状态
          dispatch({ 
            type: 'SET_USER_DATA', 
            payload: {
              ...userInfo,
              subscription: subscriptionData
            }
          });
          navigate('/profile');
        } catch (error) {
          console.error('Failed to load user data:', error);
          setMessage(t('failedToLoadUserData'));
        }
      } else {
        setMessage(t('loginFailed'));
      }
    } catch (error) {
      console.error('Login error:', error);
      setMessage(t('loginFailed'));
    }
  };

  return (
    <div className="login-container">
      <form className="login-form" onSubmit={handleSubmit}>
        <h2>{t('logIn')}</h2>
        {message && <div className="message error">{message}</div>}
        <div className="form-group">
          <label htmlFor="email">{t('email')}</label>
          <input
            type="email"
            id="email"
            value={email}
            onChange={(e) => setEmail(e.target.value)}
            required
          />
        </div>
        <div className="form-group">
          <label htmlFor="password">{t('password')}</label>
          <input
            type="password"
            id="password"
            value={password}
            onChange={(e) => setPassword(e.target.value)}
            required
          />
        </div>
        <button type="submit" className="login-button">
          {t('logIn')}
        </button>
      </form>
      <p className="toggle-text">
        {t('dontHaveAccount')} <Link to="/register">{t('register')}</Link>
      </p>
      <p className="toggle-text">
        <Link to="/forgot-password">{t('forgotPassword')}</Link>
      </p>
    </div>
  );
};

export default LoginPage;
