import React from 'react';
import { useTranslation } from 'react-i18next';
import PageLayout from './PageLayout';
import './FAQPage.css';

const FAQPage = () => {
  const { t } = useTranslation();

  const faqs = [
    {
      question: "What payment methods are available?",
      answer: "We accept all major credit cards through our secure Stripe checkout system. This includes Visa, MasterCard, American Express, and Discover."
    },
    {
      question: "How can I renew my subscription?",
      answer: "All subscriptions are set to auto-renew by default for your convenience. You can manage your subscription settings in your account profile, where you have the option to turn off auto-renewal if desired."
    },
    {
      question: "Can I cancel my subscription at any time?",
      answer: "Yes, you can cancel your subscription at any time. Once cancelled, you'll continue to have access to the service until the end of your current billing period."
    },
    {
      question: "Is there a free trial available?",
      answer: "We offer a 7-day free trial for new users. This allows you to explore all features of our Pro plan before committing to a subscription."
    },
    {
      question: "What's included in the Pro plan?",
      answer: "The Pro plan includes access to our live picked stock screener, instant insights on picked stocks, and comparison tools with key assets. For a full list of features, please check our pricing page."
    },
    {
      question: "How often is the stock data updated?",
      answer: "Our stock data is updated in real-time during market hours. For after-hours updates, we refresh data every 15 minutes."
    },
    {
      question: "Is my personal and financial information secure?",
      answer: "Yes, we take security very seriously. We use industry-standard encryption for all data transmissions and do not store any sensitive financial information on our servers."
    },
    {
      question: "Can I use the service on multiple devices?",
      answer: "Yes, you can access your account on any device with a web browser. We also have mobile apps available for iOS and Android devices."
    }
  ];

  return (
    <PageLayout>
      <div className="faq-page">
        <div className="content">
          <h1>{t('faq')}</h1>
          <div className="faq-container">
            {faqs.map((faq, index) => (
              <div key={index} className="faq-item">
                <h2>{faq.question}</h2>
                <p>{faq.answer}</p>
              </div>
            ))}
          </div>
        </div>
      </div>
    </PageLayout>
  );
};

export default FAQPage;
