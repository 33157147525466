import React from 'react';
import './Tooltip.css';

const Tooltip = ({ children, content }) => {
  return (
    <span className="tooltip-container">
      <span className="tooltip-icon">?</span>
      {children}
      <div className="tooltip-content">
        {content}
      </div>
    </span>
  );
};

export default Tooltip; 