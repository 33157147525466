import React from 'react';
import { useLocation } from 'react-router-dom';
import AppHeader from './AppHeader';
import './PageLayout.css';

const PageLayout = ({ children }) => {
  const location = useLocation();
  const isHomePage = location.pathname === '/' || location.pathname === '/home';

  return (
    <div className={`page-layout ${isHomePage ? 'home-page' : ''}`}>
      <AppHeader isTransparent={isHomePage} />
      <main className="main-content-area">
        {children}
      </main>
    </div>
  );
};

export default PageLayout;
