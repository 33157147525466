import React, { useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { callLoginApi } from '../login_api';
import { getSubscriptionInfo } from '../services/stripe_api';
import PageLayout from './PageLayout';
import './PaymentSuccessPage.css';

const PaymentSuccessPage = () => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);

  useEffect(() => {
    const updateUserData = async () => {
      try {
        // 先获取用户数据
        const userData = await callLoginApi('/load-user');
        
        if (!userData?.userid) {
          throw new Error('No user data available');
        }

        // 然后获取订阅信息
        const subscriptionData = await getSubscriptionInfo(userData.userid);

        // 更新 Redux store
        dispatch({ 
          type: 'SET_USER_DATA', 
          payload: userData
        });

        if (subscriptionData) {
          dispatch({
            type: 'UPDATE_SUBSCRIPTION_DATA',
            payload: subscriptionData
          });
        }

        setLoading(false);

        // 延迟导航到个人资料页面
        setTimeout(() => {
          navigate('/profile');
        }, 3000);
      } catch (error) {
        console.error('Failed to update user data:', error);
        setError(t('errors.paymentUpdateFailed'));
        setLoading(false);
      }
    };

    // 添加短暂延迟确保支付状态已更新
    setTimeout(() => {
      updateUserData();
    }, 1000);

  }, [dispatch, navigate, t]);

  return (
    <PageLayout>
      <div className="payment-result-page success">
        {loading ? (
          <div className="loading-container">
            <div className="loading-spinner"></div>
            <div className="loading-message">{t('processingPayment')}</div>
          </div>
        ) : error ? (
          <div className="error-container">
            <div className="error-icon">⚠️</div>
            <div className="error-message">{error}</div>
            <button onClick={() => navigate('/profile')}>{t('goToProfile')}</button>
          </div>
        ) : (
          <div className="success-container">
            <div className="success-icon">✓</div>
            <h2>{t('paymentSuccessful')}</h2>
            <p>{t('redirectingToProfile')}</p>
          </div>
        )}
      </div>
    </PageLayout>
  );
};

export default PaymentSuccessPage; 