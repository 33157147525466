import { STOCKS } from '../config/stocks';

const apiBaseUrl = 'https://my-worker.helloswx.workers.dev';

// 添加超时控制的请求函数
const makeApiRequest = async (url, options = {}) => {
  // 设置超时时间为10秒
  const timeout = 10000;
  
  try {
    const controller = new AbortController();
    const timeoutId = setTimeout(() => controller.abort(), timeout);

    const response = await fetch(url, {
      ...options,
      signal: controller.signal,
      headers: {
        'Content-Type': 'application/json',
        'Accept': 'application/json',
        'Origin': window.location.origin,
        ...options.headers
      }
    });

    clearTimeout(timeoutId);

    if (!response.ok) {
      const errorData = await response.json().catch(() => ({}));
      throw new Error(errorData.error || `Request failed: ${response.status}`);
    }

    return await response.json();
  } catch (error) {
    if (error.name === 'AbortError') {
      throw new Error('Request timeout');
    }
    console.error('API request failed:', error);
    // 返回模拟数据用于开发
    if (process.env.NODE_ENV === 'development') {
      return getMockData(url);
    }
    throw error;
  }
};

// 模拟数据
const getMockData = (url) => {
  if (url.includes('btc-overview')) {
    return {
      price: 72200.06,
      daily_return: '0.19%',
      market_cap_T: '1.40T',
      mvrv_z_score: 1.68,
      current_month: 10,
      monthly_mean: '18.81%',
      fear_greed_index: 72
    };
  }
  
  if (url.includes('stock-overview')) {
    return {
      data: [
        {
          ticker: 'MSTR',
          name: 'MicroStrategy Inc.',
          close: 247.31,
          recent_performance: '15.59%',
          bci: 'A',
          vp: 'B',
          risk: 'A',
          sentiment_rank: 'C'
        },
        // ... 可以添加更多模拟数据
      ]
    };
  }
  
  if (url.includes('btc/history')) {
    return {
      total: 100,
      data: Array.from({ length: 30 }, (_, i) => ({
        id: i + 1,
        date: new Date(Date.now() - (30 - i) * 24 * 60 * 60 * 1000).toISOString().split('T')[0],
        open_price: 65000 + Math.random() * 1000,
        high_price: 66000 + Math.random() * 1000,
        low_price: 64000 + Math.random() * 1000,
        close_price: 65500 + Math.random() * 1000,
        volume: 20000000 + Math.random() * 10000000
      }))
    };
  }
  
  if (url.includes('price-return-minute')) {
    return {
      total: 1,
      data: [{
        id: 1,
        ticker: "BTC-USD",
        current_price: 68947.9140625,
        previous_close: 68741.1171875,
        return_to_previous: 0.3008343237075063,
        all_time_high: 73738,
        return_to_ath: -6.496088770376198
      }]
    };
  }

  if (url.includes('overview-base')) {
    return {
      total: 1,
      data: [{
        id: 1,
        volume_24h: 34249846784,
        volume_10d_avg: 35943838641.7,
        previous_close: 68741.1171875,
        circulating_supply: 19777496,
        total_supply: 21000000,
        launch_date: "2009-01-03",
        all_time_high: 73738,
        ath_date: "2024-03-14T07:10:36.635Z"
      }]
    };
  }

  if (url.includes('monthly-returns')) {
    return {
      total: 123,
      data: Array.from({ length: 120 }, (_, i) => ({
        id: i + 1,
        year: 2014 + Math.floor(i / 12),
        month: (i % 12) + 1,
        price: 30000 + Math.random() * 40000,
        return_value: `${(Math.random() * 40 - 20).toFixed(2)}%`
      }))
    };
  }

  if (url.includes('monthly-stats')) {
    return {
      total: 12,
      data: Array.from({ length: 12 }, (_, i) => ({
        id: i + 1,
        month: i + 1,
        mean: `${(Math.random() * 30 - 15).toFixed(2)}%`,
        median: `${(Math.random() * 30 - 15).toFixed(2)}%`
      }))
    };
  }

  if (url.includes('etf-net-inflow/latest')) {
    return {
      date: "2024-11-04",
      daily_net_inflow: -541068862.82,
      cumulative_net_inflow: 23613574647.89,
      daily_total_volume: 2223924706.94,
      total_assets: 67441181077.93,
      btc_price: null
    };
  }

  if (url.includes('etf-net-inflow')) {
    return {
      total: 100,
      data: Array.from({ length: 100 }, (_, i) => ({
        id: i + 1,
        date: new Date(Date.now() - (100 - i) * 24 * 60 * 60 * 1000).toISOString().split('T')[0],
        net_inflow: -541068862.82 + Math.random() * 100000000,
        total_volume: 2223924706.94 + Math.random() * 100000000,
        assets: 67441181077.93 + Math.random() * 100000000
      }))
    };
  }

  // 匹配 stock-overview/{ticker} 格式的请求
  const stockOverviewMatch = url.match(/\/api\/stock-overview\/([A-Z]+)/);
  if (stockOverviewMatch) {
    const ticker = stockOverviewMatch[1];
    const stockInfo = STOCKS[ticker] || {
      name: `${ticker} Inc.`,
      sector: 'Technology'
    };

    return {
      ticker: ticker,
      name: stockInfo.name,
      sector: stockInfo.sector,
      close: 139.34 + Math.random() * 100,
      recent_performance: (-5 + Math.random() * 10).toFixed(2),
      bci: ['A', 'B', 'C', 'D', 'E'][Math.floor(Math.random() * 5)],
      vp: ['A', 'B', 'C', 'D', 'E'][Math.floor(Math.random() * 5)],
      risk: ['A', 'B', 'C', 'D', 'E'][Math.floor(Math.random() * 5)],
      sentiment_rank: ['A', 'B', 'C', 'D', 'E'][Math.floor(Math.random() * 5)]
    };
  }

  // 匹配 price-return-minute/{ticker} 格式的请求
  const priceReturnMatch = url.match(/\/api\/btc\/price-return-minute\/([A-Z]+)/);
  if (priceReturnMatch) {
    const ticker = priceReturnMatch[1];
    return {
      ticker: ticker,
      current_price: 200 + Math.random() * 100,
      previous_close: 180 + Math.random() * 100,
      current_return: (-10 + Math.random() * 20).toFixed(2)
    };
  }

  if (url.includes('/api/btc/stock-overview/')) {
    const ticker = url.split('/').pop();
    const stockInfo = STOCKS[ticker] || {
      name: `${ticker} Inc.`,
      sector: 'Technology',
      introduction: `${ticker} company description...`
    };

    return {
      ticker,
      name: stockInfo.name,
      sector: stockInfo.sector,
      previous_close: 254.31,
      market_cap: 63666507776,
      previous_volume: 35358100,
      avg_10d_volume: 15188737.5,
      recent_performance: '31.10',
      introduction: stockInfo.introduction
    };
  }

  return null;
};

// 获取 BTC Overview 数据
export async function getBtcOverview() {
  return makeApiRequest(`${apiBaseUrl}/api/btc-overview`);
}

// 获取股票概览数据
export async function getStockOverview() {
  return makeApiRequest(`${apiBaseUrl}/api/stock-overview`);
}

// ... 现有代码 ...

// 获取 BTC 价格和回报数据
export async function getBtcPriceReturn() {
    return makeApiRequest(`${apiBaseUrl}/api/btc/price-return-minute`);
  }
  
  // 获取 BTC 基础概览数据
  export async function getBtcOverviewBase() {
    return makeApiRequest(`${apiBaseUrl}/api/btc/overview-base`);
  }
  
  // 格式化数字为带T/B/M的字符串
  export const formatNumber = (number) => {
    if (number >= 1e12) return `${(number / 1e12).toFixed(2)}T`;
    if (number >= 1e9) return `${(number / 1e9).toFixed(2)}B`;
    if (number >= 1e6) return `${(number / 1e6).toFixed(2)}M`;
    return number.toLocaleString();
  };

  // 获取 BTC 历史数据
  export async function getBtcHistory() {
    return makeApiRequest(`${apiBaseUrl}/api/btc/history`);
  }

  // 获取 BTC 月度收益数据
  export async function getBtcMonthlyReturns() {
    return makeApiRequest(`${apiBaseUrl}/api/btc/monthly-returns`);
  }

  // 获取 BTC 月度统计数据
  export async function getBtcMonthlyStats() {
    return makeApiRequest(`${apiBaseUrl}/api/btc/monthly-stats`);
  }

  // 获取顶级���产详情
  export async function getTopAssetsDetails() {
    return makeApiRequest(`${apiBaseUrl}/api/btc/top-assets-details`);
  }

  // 获取总市值更新数据
  export async function getTotalMarketCapsUpdate() {
    return makeApiRequest(`${apiBaseUrl}/api/btc/total-market-caps-update`);
  }

  // 获取比特币市值
  export async function getBtcMarketCap() {
    return makeApiRequest(`${apiBaseUrl}/api/btc/top-assets-details/Bitcoin`);
  }

  // 获取 BCI-A 历史数据
  export async function getBciAHistory() {
    return makeApiRequest(`${apiBaseUrl}/api/btc/bci-a-history`);
  }

  // 添加获取 BCI-A 最新数据的接口
  export async function getBciALatest() {
    return makeApiRequest(`${apiBaseUrl}/api/btc/bci-a-latest`);
  }

  // 获取顶级资产市值历史数据
  export async function getTopMarketCapsHistory() {
    return makeApiRequest(`${apiBaseUrl}/api/btc/top-market-caps-history`);
  }

  // 获取ETF净流入数据
  export async function getEtfNetInflowLatest() {
    return makeApiRequest(`${apiBaseUrl}/api/btc/etf-net-inflow/latest`);
  }

  // 获取ETF净流入历史数据
  export async function getEtfNetInflowHistory() {
    return makeApiRequest(`${apiBaseUrl}/api/btc/etf-net-inflow`);
  }

  // 获取单个股票详情数据
  export async function getStockData(ticker) {
    // 在实际API实现之前使用模拟数据
    const stockInfo = STOCKS[ticker] || {
      name: `${ticker} Inc.`,
      sector: 'Technology',
      introduction: `${ticker} company description...`
    };

    return {
      ticker: ticker,
      companyName: stockInfo.name,
      price: 200 + Math.random() * 100,
      priceChange: -10 + Math.random() * 20,
      marketCap: (10 + Math.random() * 90).toFixed(2),
      volume: {
        previous: (1 + Math.random() * 4).toFixed(2),
        avg10d: (1 + Math.random() * 4).toFixed(2)
      },
      eps: (-3 + Math.random() * 6).toFixed(2),
      sector: stockInfo.sector,
      introduction: stockInfo.introduction,
      keyAspects: [
        {
          title: 'Bitcoin Holdings',
          description: 'Company specific Bitcoin holdings and strategy.'
        },
        {
          title: 'Core Business',
          description: 'Company specific core business description.'
        },
        {
          title: 'Market Position',
          description: 'Company specific market position and strategy.'
        }
      ],
      btcCorrelatedIndex: {
        value: ['very weak', 'weak', 'medium', 'strong', 'very strong'][Math.floor(Math.random() * 5)],
        score: Math.random()
      },
      valuationPremium: {
        value: ['low', 'medium', 'high'][Math.floor(Math.random() * 3)],
        score: Math.random()
      },
      risk: {
        value: ['low', 'medium', 'high', 'very high'][Math.floor(Math.random() * 4)],
        score: Math.random()
      },
      newsSentiment: {
        value: ['negative', 'neutral', 'positive'][Math.floor(Math.random() * 3)],
        score: Math.random()
      }
    };
  }

  // 获取单个股票概览数据
  export async function getStockDetailOverview(ticker) {
    return makeApiRequest(`${apiBaseUrl}/api/stock-overview/${ticker}`);
  }

  // 添加获取股票价格和回报数据的函数
  export async function getStockPriceReturn(ticker) {
    return makeApiRequest(`${apiBaseUrl}/api/btc/price-return-minute/${ticker}`);
  }

  // 添加新的股票详情接口
  export async function getStockBasicInfo(ticker) {
    return makeApiRequest(`${apiBaseUrl}/api/btc/stock-overview/${ticker}`);
  }

  // 获取股票风险数据
  export async function getStockRiskData(ticker) {
    return makeApiRequest(`${apiBaseUrl}/api/btc/stock-risk/${ticker}`);
  }

  // 获取股票历史价格数据
  export async function getStockHistory(ticker) {
    return makeApiRequest(`${apiBaseUrl}/api/stock/history/${ticker}`);
  }

  