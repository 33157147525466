import React from 'react';
import { useTranslation } from 'react-i18next';
import ReactSpeedometer from "react-d3-speedometer";
import './FearGreedMeter.css';
import Tooltip from './Tooltip';

const FearGreedMeter = ({ value }) => {
  const { t } = useTranslation();

  // 固定的区间颜色，从绿色到红色
  const getSegmentColors = () => {
    return ["#4CAF50", "#8BC34A", "#FDD835", "#FB8C00", "#E53935"]; // 极度贪婪、贪婪、中性、恐惧、极度恐惧
  };

  // 根据数值动态设置数字颜色，从绿色到红色
  const getNumberColor = (value) => {
    if (value <= 20) return "#66BB6A"; // 极度贪婪
    if (value <= 40) return "#A5D6A7"; // 贪婪
    if (value <= 60) return "#FFF59D"; // 中性
    if (value <= 80) return "#EF9A9A"; // 恐惧
    return "#E57373"; // 极度恐惧
  };

  return (
    <div className="fear-greed-meter">
      <ReactSpeedometer
        value={value}
        minValue={0}
        maxValue={100}
        segments={5}
        currentValueText=""
        customSegmentLabels={[
          {
            text: "Extreme Greed",
            position: "OUTSIDE",
            color: "#555",
          },
          {
            text: "Greed",
            position: "OUTSIDE",
            color: "#555",
          },
          {
            text: "Neutral",
            position: "OUTSIDE",
            color: "#555",
          },
          {
            text: "Fear",
            position: "OUTSIDE",
            color: "#555",
          },
          {
            text: "Extreme Fear",
            position: "OUTSIDE",
            color: "#555",
          },
        ]}
        segmentColors={getSegmentColors()}
        needleColor="#FF5252"
        needleTransition="easeElastic"
        needleTransitionDuration={3000}
        needleHeightRatio={0.6}
        labelFontSize="11px"
        valueTextFontSize="0"
        width={380}
        height={200}
        paddingVertical={30}
        valueTextFontWeight="600"
        valueLocation={{ x: 0.5, y: 0.75 }}
      />
      <div className="fear-greed-index">
        <Tooltip content={t('tooltips.fearGreedIndex')}>
          <span>Fear & Greed Index: </span>
        </Tooltip>
        <span className="fear-greed-value" style={{ color: getNumberColor(value) }}>{value}</span>
      </div>
    </div>
  );
};

export default FearGreedMeter;
