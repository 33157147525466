import React from 'react';
import { PieChart, Pie, Cell } from 'recharts';
import { useTranslation } from 'react-i18next';
import Tooltip from '../Tooltip';
import './RiskMetrics.css';

const RISK_COLORS = {
  'A': {
    inner: '#4CAF50',
    outer: ['#4CAF50', '#4CAF50', '#4CAF50', '#4CAF50']
  },
  'B': {
    inner: '#4CAF50',
    outer: ['#4CAF50', '#4CAF50', '#4CAF50', '#F44336']
  },
  'C': {
    inner: ['#4CAF50', '#F44336'],
    outer: ['#4CAF50', '#4CAF50', '#F44336', '#F44336']
  },
  'D': {
    inner: '#F44336',
    outer: ['#4CAF50', '#F44336', '#F44336', '#F44336']
  },
  'E': {
    inner: '#F44336',
    outer: ['#F44336', '#F44336', '#F44336', '#F44336']
  }
};

const RISK_LABELS = {
  'A': 'Very Low Risk',
  'B': 'Low Risk',
  'C': 'Medium Risk',
  'D': 'High Risk',
  'E': 'Very High Risk'
};

const RiskPieChart = ({ grade }) => {
  const outerData = RISK_COLORS[grade].outer.map((color, index) => ({
    name: `outer-${index}`,
    value: 100 / RISK_COLORS[grade].outer.length
  }));

  const innerData = Array.isArray(RISK_COLORS[grade].inner)
    ? RISK_COLORS[grade].inner.map((color, index) => ({
        name: `inner-${index}`,
        value: 100 / RISK_COLORS[grade].inner.length
      }))
    : [{ name: 'inner', value: 100 }];

  return (
    <div className="risk-chart-container">
      <PieChart width={180} height={180}>
        <Pie
          data={outerData}
          cx={90}
          cy={90}
          innerRadius={60}
          outerRadius={80}
          dataKey="value"
          startAngle={90}
          endAngle={-270}
        >
          {outerData.map((entry, index) => (
            <Cell key={`outer-${index}`} fill={RISK_COLORS[grade].outer[index]} />
          ))}
        </Pie>
        <Pie
          data={innerData}
          cx={90}
          cy={90}
          innerRadius={0}
          outerRadius={60}
          dataKey="value"
          startAngle={90}
          endAngle={-270}
        >
          {Array.isArray(RISK_COLORS[grade].inner)
            ? innerData.map((entry, index) => (
                <Cell key={`inner-${index}`} fill={RISK_COLORS[grade].inner[index]} />
              ))
            : <Cell fill={RISK_COLORS[grade].inner} />
          }
        </Pie>
      </PieChart>
      <div className="risk-level-label">
        {RISK_LABELS[grade]}
      </div>
    </div>
  );
};

const SectionTitle = ({ title }) => (
  <div className="risk-section-title">
    <span className="risk-section-text">{title}</span>
  </div>
);

const RiskMetricItem = ({ label, value, tooltip }) => (
  <div className="risk-metric-item">
    <div className="risk-metric-label">
      {tooltip ? (
        <Tooltip content={tooltip}>
          {label}
        </Tooltip>
      ) : label}
    </div>
    <div className="risk-metric-value">{value}</div>
  </div>
);

const RiskMetrics = ({ riskData }) => {
  const { t } = useTranslation();
  
  const formatValue = (value, isPercentage = false) => {
    if (value === undefined || value === null) return 'N/A';
    if (typeof value !== 'number') return value;
    const formattedValue = value.toFixed(2);
    return isPercentage ? `${formattedValue}%` : formattedValue;
  };

  return (
    <div className="risk-metrics-container">
      <div className="risk-section">
        <SectionTitle title="Trading Risk" />
        <div className="risk-content">
          <RiskPieChart grade={riskData.TRI_Grade} />
          <div className="risk-metrics">
            <div className="metrics-grid">
              <RiskMetricItem 
                label="Volatility (5Y)"
                value={`${formatValue(riskData.Volatility * 100)}%`}
              />
              <RiskMetricItem 
                label="Beta (BTC) (5Y)"
                value={formatValue(riskData.Beta_BTC)}
              />
              <RiskMetricItem 
                label="Volume (60D)"
                value={formatValue(riskData.Volume_60D)}
              />
              <RiskMetricItem 
                label={<Tooltip content={t('tooltips.turnoverRatio')}>Turnover ratio (60D)</Tooltip>}
                value={`${formatValue(riskData.Turnover_ratio * 100)}%`}
              />
              <RiskMetricItem 
                label={<Tooltip content={t('tooltips.maxDrawdown')}>Maximum drawdown (5Y)</Tooltip>}
                value={`${formatValue(riskData.Maximum_drawdown * 100)}%`}
              />
              <RiskMetricItem 
                label={<Tooltip content={t('tooltips.var5')}>VaR (5%) (5Y)</Tooltip>}
                value={`${formatValue(riskData.VaR * 100)}%`}
              />
              <RiskMetricItem 
                label={<Tooltip content={t('tooltips.sharpeRatio')}>Sharpe ratio (5Y)</Tooltip>}
                value={formatValue(riskData.Sharpe_ratio)}
              />
              <RiskMetricItem 
                label="Sortino Ratio (5Y)"
                value={formatValue(riskData.Sortino_Ratio)}
              />
            </div>
          </div>
        </div>
      </div>

      <div className="risk-section">
        <SectionTitle title="Financial Risk" />
        <div className="risk-content">
          <RiskPieChart grade={riskData.FRI_rank} />
          <div className="risk-metrics">
            <div className="metrics-grid">
              <RiskMetricItem 
                label="Debt to Equity (mrq)"
                value={`${formatValue(riskData.Debt_to_Equity)}%`}
              />
              <RiskMetricItem 
                label={<Tooltip content={t('tooltips.currentRatio')}>Current Ratio (mrq)</Tooltip>}
                value={formatValue(riskData.Current_Ratio)}
              />
              <RiskMetricItem 
                label="Profit Margin"
                value={`${formatValue(riskData.Profit_Margin * 100)}%`}
              />
              <RiskMetricItem 
                label={<Tooltip content={t('tooltips.roa')}>ROA (ttm)</Tooltip>}
                value={`${formatValue(riskData.ROA * 100)}%`}
              />
              <RiskMetricItem 
                label={<Tooltip content={t('tooltips.interestCoverage')}>Interest Coverage Ratio</Tooltip>}
                value={formatValue(riskData.Interest_Coverage_Ratio)}
              />
              <RiskMetricItem 
                label={<Tooltip content={t('tooltips.altmanZScore')}>Altman Z Score</Tooltip>}
                value={formatValue(riskData.Altman_Z_Score)}
              />
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default RiskMetrics; 