import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import './QASection.css';

// 辅助函数：处理文本加粗
const formatText = (text) => {
  // 定义需要加粗的关键词
  const boldTerms = [
    'Bitcoin-related US stocks',
    'BCI',
    'VP',
    'Risk',
    'blockchain',
    'BTC Correlated Index (BCI)',
    'Valuation Premium (VP)',
    'complete stock pool',
    'AI Recommendation Report',
    'paid users',
    'multiple indicators',
    'Bitcoin price',
    'trading volume',
    'market cap',
    'MVRV-Z score',
    'Fear & Greed Index',
    'free trial',
    // 中文关键词
    '比特币相关的美股',
    'BTC相关指数',
    '估值溢价',
    '完整的股票池',
    'AI推荐报告',
    '付费用户',
    '多个指标',
    '比特币价格',
    '交易量',
    '市值',
    'MVRV-Z分数',
    '恐惧与贪婪指数',
    '免费试用'
  ];

  // 创建正则表达式，匹配所有关键词（考虑边界）
  const regex = new RegExp(`(${boldTerms.map(term => term.replace(/[.*+?^${}()|[\]\\]/g, '\\$&')).join('|')})`, 'g');

  // 将文本分割成段落，并对每个段落应用加粗处理
  const parts = text.split(regex);
  
  return parts.map((part, index) => {
    if (boldTerms.includes(part)) {
      return <strong key={index}>{part}</strong>;
    }
    return part;
  });
};

const QAItem = ({ question, answer }) => {
  const [isExpanded, setIsExpanded] = useState(false);

  return (
    <div className="qa-item">
      <div 
        className={`qa-question ${isExpanded ? 'expanded' : ''}`}
        onClick={() => setIsExpanded(!isExpanded)}
      >
        <span>{formatText(question)}</span>
        <span className="qa-toggle">{isExpanded ? '−' : '+'}</span>
      </div>
      {isExpanded && (
        <div className="qa-answer">
          {formatText(answer)}
        </div>
      )}
    </div>
  );
};

const QASection = () => {
  const { t } = useTranslation();

  return (
    <div className="qa-section">
      <h2>{t('qa.title')}</h2>
      <div className="qa-list">
        {[...Array(8)].map((_, index) => (
          <QAItem
            key={index + 1}
            question={t(`qa.questions.${index + 1}.q`)}
            answer={t(`qa.questions.${index + 1}.a`)}
          />
        ))}
      </div>
    </div>
  );
};

export default QASection; 